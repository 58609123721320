import * as React from "react";
import { useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { useSearchParams } from "react-router-dom";
import { getTranslated } from "../functions/getTranslatedLevel";

interface IFormCheckBox {
  updateState: Function;
  data: any;
}

const FormCheckBox = ({ updateState, data }: IFormCheckBox) => {
  const [isXsScreen, setIsXsScreen] = React.useState<Boolean>(window.innerWidth < 900 ? true : false);
  window.addEventListener('resize',()=>{
    if(window.innerWidth < 900) {
      setIsXsScreen(true);
    }
    else{
      setIsXsScreen(false);
    }
  })
  const [queryParams] = useSearchParams();
  let fmType = queryParams.get("fmType");
  const formData = useSelector((state: any) => {
    if(data.fmFormType === 'fm'){
      return state.fm.formData.roleContext;
    }
    else if (data.fmFormType === 'fmReEvaluate'){
      return state.fmReEvaluate.formData.roleContext;
    }else{
      return state.fmEvaluateNewRole.formData.roleContext;
    } 
  });

  const parseLines = (value: any) => {
    let newStr: string = value.replace(/\n/g, "<br/>");
    return newStr;
  };

  const searchForCheckBox = (answerId: any) => {
    let answerIdArray = [] as any;
    formData.forEach((obj: any) => {
      if (obj.criteriaId === data.criteriaCode) {
        answerIdArray.push(obj.answerId);
      }
    });
    return answerIdArray.includes(answerId) ? true : false;
  };

  const handleChange = (event: any, optionCode: any) => {
    updateState({
      criteriaId: data.criteriaCode,
      answerId: optionCode,
    });
  };

  return (
    <>
      <div style={{ alignItems: "center", display: "flex" }} className="label">
        <b>
          <span
            style={{
              fontFamily: "initial",
              fontSize: "120%",
              fontWeight: "bold",
            }}
            dangerouslySetInnerHTML={{ __html: getTranslated(`dynamic.${data.criteriaInfo}`) || data.criteriaInfo }}
          ></span>
        </b>
        {/* {data.criteriaTooltip !== undefined &&
        data.criteriaTooltip !== "" &&
        data.criteriaTooltip !== null ? (
          <Tooltip title={getTranslated(`dynamic.${data.criteriaTooltip}`) || data.criteriaTooltip} arrow>
            <HelpOutlineIcon />
          </Tooltip>
        ) : (
          ""
        )} */}
      </div>
      <div style={{ alignItems: "center", display: "flex", marginTop: "10px" }}>
        <FormControl fullWidth>
          <FormGroup>
            {fmType === "fm3" ? (
              <Grid container>
                {data.options.map((opt: any, index: number) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    key={opt.optionCode}
                    style={{ alignItems: "center", display: "flex", paddingLeft: index%2 !== 0 && !isXsScreen ? "24px" : '0px' }}
                  >
                    <FormControlLabel
                      control={
                        <span
                          style={{
                            display: "flex",
                            alignSelf: "flex-start",
                          }}
                        >
                          <Checkbox
                            onChange={(e) => handleChange(e, opt.optionCode)}
                            inputProps={{ "aria-label": "controlled" }}
                            checked={searchForCheckBox(opt.optionCode)}
                            value={opt.optionCode}
                            disabled={data.readOnly}
                          />
                        </span>
                      }
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: parseLines(getTranslated(`dynamic.${opt.options}`) || opt.options),
                          }}
                        ></span>
                      }
                    />
                    {/* {opt.optionExplanation !== undefined &&
                    opt.optionExplanation !== "" &&
                    opt.optionExplanation !== null ? (
                      <Tooltip
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: parseLines(getTranslated(`dynamic.${opt.optionExplanation}`) || opt.optionExplanation),
                            }}
                          ></span>
                        }
                        arrow
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <>
                {data.options.map((opt: any) => (
                  <Grid
                    key={opt.optionCode}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <FormControlLabel
                      control={
                        <span
                          style={{
                            display: "flex",
                            alignSelf: "flex-start",
                          }}
                        >
                          <Checkbox
                            onChange={(e) => handleChange(e, opt.optionCode)}
                            inputProps={{ "aria-label": "controlled" }}
                            checked={searchForCheckBox(opt.optionCode)}
                            value={opt.optionCode}
                            disabled={data.readOnly}
                          />
                        </span>
                      }
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: parseLines(getTranslated(`dynamic.${opt.options}`) || opt.options),
                          }}
                        ></span>
                      }
                    />
                    {/* {opt.optionExplanation !== undefined &&
                    opt.optionExplanation !== "" &&
                    opt.optionExplanation !== null ? (
                      <Tooltip
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: parseLines(getTranslated(`dynamic.${opt.optionExplanation}`) || opt.optionExplanation),
                            }}
                          ></span>
                        }
                        arrow
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                ))}
              </>
            )}
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
};
export default FormCheckBox;
