import * as React from "react";
import FormControl from "@mui/material/FormControl";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControlFlex } from "../styled";
import Grid from "@mui/material/Grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ToolTip } from "./ToolTip";
import { TOOLTIPS } from "../constants";
import { useSearchParams } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import { getTranslated } from "../functions/getTranslatedLevel";
import PaqSubmitDialog from "../../app/dialog/PaqSubmitDialog";


interface IStakeholderInteraction {
  updateState: Function;
  values: any;
  stakeholderOptions: Array<any>;
  data: any;
}

const StakeholderInteraction = ({
  updateState,
  values,
  stakeholderOptions,
  data,
}: IStakeholderInteraction) => {
  const valuesWithId = values.map((element: any)=>{
    return {
      ...element,
      id: nanoid()
    }
  })
  
  /*
  props .values

[
        {
          stakeholderId: null,
          frequency: "yearly",
        },
        {
          stakeholderId: null,
          frequency: "yearly",
        },
      ],
  */
  const [queryParams] = useSearchParams();
  //handle Modal variables and methods - START
  const [isError, setIsError] = React.useState<boolean>(false);
  const [errMsg, setErrMsg] = React.useState<string>("");
  const handleModalOpen = (msg: string) => {
    setIsError(true)
    setErrMsg(msg);
  }
  const handleModalClose = () => {
    setErrMsg("");
    setIsError(false)
  }
  //handle Modal variables and methods - END
  const handleChange = (key: any, objProp: string, event: any) => {
    ////
    // 1. Make a shallow copy of the items
    let items = [...values];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[key] };
    // 3. Replace the property you're intested in
    item[objProp] = event.target.value;
    // 4. Put it back into our array.
    items[key] = item;

    ///Find same stakeholderId exists in value or not
    if (objProp == "stakeholderId") {
      let findVal = values.find(
        (o: any) =>
          o.stakeholderId != null && o.stakeholderId == item.stakeholderId
      );
      if (findVal != undefined) {
        handleModalOpen(
          getTranslated("alert.duplicateStakeholder") || "Duplicate Stakeholder Entry !! Please select different stakeholder"
        );
        return false;
      }
    }

    updateState({
      ["stakeholder"]: items,
    });
  };

  const addNew = () => {
    //add new item to  state
    updateState({
      ["stakeholder"]: [
        ...values,
        {
          stakeholderId: null,
          frequency: "yearly",
        },
      ],
    });
  };

  const removeItem = (index: number) => {
    let arr = [...values];
    if (arr.length > 1) {
      arr.splice(index, 1);
      updateState({
        ["stakeholder"]: arr,
      });
    } else {
      handleModalOpen("At least One Stakeholder is required");
    }
  };

  return (
    <>
      <FormControlFlex>
        {isError && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} />}
        <div className="label">
          <span>
            {getTranslated("step3.stackholder") || "Highest Level of Stakeholder Interaction and Frequency"}{" "}
            <span className={`input-defination`}>
              ({getTranslated("others.plusButton") || "If needed, use (+) button to add a row"})
            </span>
          </span>
          <span style={{ color: "white", marginLeft: 5 }}>*</span>
          {/* <ToolTip title={getTranslated("tooltips.stakeholder") || TOOLTIPS.Stakeholder} /> */}
          {/* Add new button */}
          {data.readOnly || queryParams.get("tr") === "yes" ? (
            ""
          ) : (
            <AddCircleOutlineIcon
              onClick={addNew}
              style={{ cursor: "pointer", color: "green" }}
            />
          )}
        </div>
        <div>
          {valuesWithId.map((item: any, i: number) => {
            return (
              <Grid container spacing={1} mb={1} key={item.id}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Select
                      error={
                        item.stakeholderId !== null &&
                        item.stakeholderId !== "" &&
                        item.stakeholderId !== 0
                          ? false
                          : true
                      }
                      size="small"
                      value={item.stakeholderId}
                      label=""
                      onChange={(e) => handleChange(i, "stakeholderId", e)}
                      disabled={
                        data.readOnly != undefined && data.readOnly === true
                          ? true
                          : false
                      }
                      title={
                        stakeholderOptions.find((o: any) => {
                          return o.value == item.stakeholderId;
                        })?.name
                      }
                    >
                      <MenuItem disabled value={0}>
                        <em>{getTranslated("others.selectStakeholder") || "Select Stakeholder"}</em>
                      </MenuItem>
                      {stakeholderOptions.map((item: any, i: number) => {
                        return (
                          <MenuItem value={item.value} key={item.value}>
                            {getTranslated(`dynamic.${item.name}`) ||item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={
                    data.readOnly ||
                    queryParams.get("tr") === "yes" ||
                    values.length == 1
                      ? 6
                      : 5
                  }
                >
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      value={item.frequency}
                      label=""
                      onChange={(e) => handleChange(i, "frequency", e)}
                      disabled={
                        data.readOnly != undefined && data.readOnly === true
                          ? true
                          : false
                      }
                    >
                      <MenuItem disabled value={""}>
                        <em>{getTranslated("others.selectFrequency") || "Select Frequency"}</em>
                      </MenuItem>
                      <MenuItem value={"daily"}>{getTranslated(`dynamic.daily`) || "Daily"}</MenuItem>
                      <MenuItem value={"weekly"}>{getTranslated(`dynamic.weekly`) || "Weekly"}</MenuItem>
                      <MenuItem value={"monthly"}>{getTranslated(`dynamic.monthly`) || "Monthly"}</MenuItem>
                      <MenuItem value={"quarterly"}>{getTranslated(`dynamic.quarterly`) || "Quarterly"}</MenuItem>
                      <MenuItem value={"yearly"}>{getTranslated(`dynamic.yearly`) || "Yearly"}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Delete icons */}
                {data.readOnly ||
                queryParams.get("tr") === "yes" ||
                values.length == 1 ? (
                  ""
                ) : (
                  <Grid
                    item
                    xs={12}
                    md={1}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <div style={{ textAlign: "right" }}>
                      <DeleteForeverIcon
                        onClick={() => {
                          removeItem(i);
                        }}
                        style={{ cursor: "pointer", color: "red" }}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </div>
      </FormControlFlex>
    </>
  );
};
export default StakeholderInteraction;
