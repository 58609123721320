import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgressWithLabel from "../../app/progress-bar/LinearProgressWithLabel";
import { useSelector } from "react-redux";
import {
  updateClickedOnDraft,
  nextStep,
  prevStep,
  goToLastPage,
  goFromLandingPageToForm,
  viewForm,
  updateReqId,
  updateUserId,
  getDraftedData,
  getPreDefinedData,
  submitPositionDetails,
  submitRoleContext,
  submitKpi,
  submitHierarchy,
  updateFormLockStatus,
  updateAccessToLastPage,
  submitHierarchyNoToast,
  submitPaq,
  getTrAdditionalDetails,
  createTrHr,
  generatePaqPdf,
  getFormStatus,
  updateAuthorize,
  getQueryParams,
  getUserNameFromGpid,
  updatePaqActiveStep,
  updateOktaUserData,
  updateOktaUserRole,
  goToLandingPage,
  skipLandingPage,
  updateStepNumber,
  updateLanguage,
  getPositionDetailsMaster,
  updateIsReviewAndSubmit
} from "./paqEvaluateNewRoleSlice";
import PaqLandingPage from "./PaqLandingPage";
import PaqStepOne from "./steps/step-one/PaqStepOne";
import PaqStepTwo from "./steps/step-two/PaqStepTwo";
import PaqStepFour from "./steps/step-four/PaqStepFour";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonsContainer,
} from "../../app/styled";
import { useAppDispatch } from "../../app/hooks";
import PaqStepThree from "./steps/step-three/PaqStepThree";
import {
  heirarchyValidate,
  positionDetailsValidate,
  roleContextValidate,
} from "./validation";
import { kpisValidate } from "../paq/Validation";
import { FINAL_STEP_MESSAGE } from "../../app/constants";
import TrEvaluationPage from "./steps/trEvaluationPage/TrEvaluationPage";
import Button from "@mui/material/Button";
import { getPaqEvaluateNewRolePdfPayload } from "./getPaqEvaluateNewRolePdfPayload";
import { useOktaAuth } from "@okta/okta-react";
import {
  deleteFileNames,
  validateRegexOnPayload,
  validateTrHr,
} from "../../app/functions/CommonFunctions";
import { Divider } from "@mui/material";
import {
  DEFAULT_GPID_LENGTH,
  DEFAULT_GPID_REGEX,
  VALIDATION_ERROR_MESAGE,
} from "../../configs/defaults";
import { EditButton } from "../../app/form-inputs/EditButton";
import Header from "../header/Header";
import { getTranslated } from "../../app/functions/getTranslatedLevel";
import PaqSubmitDialog from "../../app/dialog/PaqSubmitDialog";
import { fetchQueryParams } from "./paqEvaluateNewRoleAPI";

const steps = ["POSITION DETAILS", "ROLE CONTEXT", "KPIs", "HIERARCHY"];

const PaqEvaluateNewRole = () => {
  const HR_Manager = "HR MANAGER";
  const { authState } = useOktaAuth();
  const oktaClaims: any = authState?.idToken?.claims
    ? authState.idToken.claims
    : null;
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const [queryParams, setQueryParams] = useSearchParams();
  //handle Modal variables and methods - START
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isConfirmModal, setConfirmModal] = React.useState<boolean>(false);
  const [errMsg, setErrMsg] = React.useState<string>("");
  const handleModalOpen = (msg: string) => {
    setIsError(true)
    setErrMsg(msg);
  }
  const handleModalClose = () => {
    setErrMsg("");
    setIsError(false)
    setConfirmModal(false);
  }
  const handleConfirmModalOpen = (msg: string) => {
    setConfirmModal(true)
    setErrMsg(msg);
  }
  //handle Modal variables and methods - END
  //get data from url query params
  // appDispatch(updateUserId(oktaClaims?.gpid));
  appDispatch(updateOktaUserData(oktaClaims));
  let requestId = queryParams.get("requestId");
  let showDraftedValue = queryParams.get("showDraftedValue");
  let showSummary = queryParams.get("showSummary");
  // fetching query params form Manager not TR
  let userRole: any =
    oktaClaims?.pepapphrjepcroles[0] == "Manager" || oktaClaims?.pepapphrjepcroles?.length === 0
      ? "manager"
      : oktaClaims?.pepapphrjepcroles[0] == "TRUsers"
        ? "tr"
        : oktaClaims?.pepapphrjepcroles[0] == HR_Manager ? HR_Manager : "invalid";
  appDispatch(updateOktaUserRole(userRole));

  const showLandingPage = useSelector(
    (state: any) => state.paqEvaluateNewRole.showLandingPage
  );
  const getRequestDetails = async (payload: any) => {
    let res = await fetchQueryParams(payload);
    if (res.userGPID) {
      appDispatch(updateUserId(res.userGPID));
    }
  }
  React.useEffect(() => {
    // if (userRole === "tr") {
    //   appDispatch(updateAuthorize(true));
    // } else if (userRole === "manager" || userRole === HR_Manager) {
    let payload = {
      requestId: requestId,
      userGPID: oktaClaims?.gpid,
    };
    getRequestDetails(payload);
    appDispatch(getQueryParams(payload));
    // if (userRole === HR_Manager) {
    appDispatch(updateAuthorize(true));
    // }
    // }
  }, [requestId, oktaClaims?.gpid, userRole, appDispatch]);

  const paqEvaluateNewRoleState = useSelector(
    (state: any) => state.paqEvaluateNewRole
  );
  const paqActiveStep = useSelector(
    (state: any) => state.paqEvaluateNewRole.paqActiveStep
  );
  const progress = useSelector(
    (state: any) => state.paqEvaluateNewRole.paqProgress
  );
  const formLoader = useSelector(
    (state: any) => state.paqEvaluateNewRole.formLoader
  );
  const hasAccessToLastPage = useSelector(
    (state: any) => state.paqEvaluateNewRole.hasAccessToLastPage
  );
  const showLastPage = useSelector(
    (state: any) => state.paqEvaluateNewRole.showLastPage
  );
  const formLocked = useSelector(
    (state: any) => state.paqEvaluateNewRole.formLocked
  );
  const fromSummary = useSelector(
    (state: any) => state.paqEvaluateNewRole.fromSummary
  );

  React.useEffect(() => {
    const param = queryParams.get('step');
    if (param) {
      // skipLandingPage
      appDispatch(skipLandingPage());
      // update active step
      appDispatch(updateStepNumber(Number(param)))
      // 👇️ delete each query param
      queryParams.delete('step');
      // 👇️ update state after
      setQueryParams(queryParams);
    }
  }, [queryParams])

  React.useEffect(() => {
    if (paqActiveStep == 4) {
      appDispatch(updatePaqActiveStep(3));
      let queryString = window.location.search;
      navigate(`/paq-evaluate-new-role${queryString}&showSummary=true`);
    }
  }, [paqActiveStep]);

  //Master Data
  const paqEvaluateNewRoleMasterData = useSelector(
    (state: any) => state.paqEvaluateNewRole.masterData
  );

  //Form datas
  const paqEvaluateNewRoleFormData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData
  );
  //1. Position details form data
  const positonDetailsFormData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData.stepOne
  );
  //2. role context
  const roleContextsFormData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData.stepTwo
  );
  //3. kpi form data
  const kpisFormData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData.stepThree
  );
  //4. Hierarchy form data
  const hierarchyFormData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData.stepFour
  );
  //5. trEvaluationStep form data
  const trEvaluationFormData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData.trEvaluationStep
  );

  const stepForm = (param: number) => {
    switch (param) {
      case 0:
        return <PaqStepOne updateFormData={updateFormData} />;
      case 1:
        return <PaqStepTwo updateFormData={updateFormData} />;
      case 2:
        return <PaqStepThree updateFormData={updateFormData} />;
      case 3:
        return <PaqStepFour updateFormData={updateFormData} />;
      default:
        return <PaqStepOne updateFormData={updateFormData} />;
    }
  };

  const stepNameAndEditButton = (step: number) => {
    return <EditButton stepName={getTranslated(`steps.${step}`) || steps[step]} onEdit={() => onEdit(step)} />;
  };

  //Main dispatch
  const updateFormData = (params: any) => {
    appDispatch(params);
  };
  const handleNextAndSubmit = (saveDraft = false, isReviewAndSubmit = false) => {
    let moveToNextStep = false;
    appDispatch(updateClickedOnDraft(saveDraft));
    appDispatch(updateIsReviewAndSubmit(isReviewAndSubmit));
    //Common value
    let commonPayload = {
      requestId: paqEvaluateNewRoleState.requestId,
      user: paqEvaluateNewRoleState.userId,
    };
    //form submit with draft state and
    if (paqActiveStep === 0) {
      const { submittedDate, ...rest } = positonDetailsFormData;
      let insertPayload = { ...commonPayload, status: "Draft", ...rest };

      if (saveDraft || positionDetailsValidate(insertPayload)) {
        //Check regex
        if (
          !saveDraft &&
          !validateRegexOnPayload(insertPayload, {
            newManagerGpid: DEFAULT_GPID_REGEX,
          })
        ) {
          handleModalOpen(VALIDATION_ERROR_MESAGE);
          return false;
        }

        appDispatch(submitPositionDetails(insertPayload));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen((getTranslated("alert.fillRequiredFields") || "Please fill required fields") + " !!");
      }
    }
    if (paqActiveStep === 1) {
      let insertPayload = { ...commonPayload, ...roleContextsFormData };

      if (
        saveDraft ||
        roleContextValidate(insertPayload, paqEvaluateNewRoleMasterData)
      ) {
        appDispatch(submitRoleContext(insertPayload));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
      }
    }
    if (paqActiveStep === 2) {
      let insertPayload = {
        ...commonPayload,
        ...kpisFormData,
      };

      if (saveDraft || kpisValidate(insertPayload)) {
        appDispatch(submitKpi(insertPayload));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen((getTranslated("alert.fillRequiredFields") || "Please fill required fields") + " !!");
      }
    }
    if (paqActiveStep === 3) {
      let insertPayload = {
        ...commonPayload,
        status: "Draft",
        ...hierarchyFormData,
      };
      //Main form fields submit
      if (saveDraft || heirarchyValidate(insertPayload)) {
        appDispatch(submitHierarchy(deleteFileNames(insertPayload)));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
      }
    }
    //Submit hiererchey details when save as draft from another step
    if (paqActiveStep !== 3) {
      let insertPayload = {
        ...commonPayload,
        status: "Draft",
        ...hierarchyFormData,
      };
      appDispatch(submitHierarchyNoToast(deleteFileNames(insertPayload)));
    }
    return moveToNextStep;
  };

  const handleLandingPageToForm = () => {
    appDispatch(goFromLandingPageToForm());
  };

  React.useEffect(() => {
    if (paqEvaluateNewRoleState.authorize && paqEvaluateNewRoleState.userId) {
      if (paqEvaluateNewRoleState.pdfGenerated) {
        let insertPayload = {
          requestId: paqEvaluateNewRoleState.requestId,
          status: "Draft",
          user: paqEvaluateNewRoleState.userId,
        };
        insertPayload.status = "Submitted";
        let payload = { ...insertPayload, ...hierarchyFormData };
        appDispatch(submitPaq(deleteFileNames(payload)));
      }
    }
  }, [paqEvaluateNewRoleState.pdfGenerated, paqEvaluateNewRoleState.authorize, paqEvaluateNewRoleState.userId]);

  const handleSubmit = () => {
    const formDetails = getPaqEvaluateNewRolePdfPayload(
      paqEvaluateNewRoleFormData,
      paqEvaluateNewRoleMasterData
    );
    const payload: any = {
      requestId,
      user: paqEvaluateNewRoleState.userId,
      formType: "Position Analysis Questionnaire Re-Evaluate",
      formDetails,
    };
    appDispatch(generatePaqPdf(payload));
  };

  const handlePrev = () => {
    if (paqActiveStep > 0) {
      appDispatch(prevStep());
    }
  };

  const handleLastNext = () => {
    let trAdditionalDetailsPayload = {
      functionCode: paqEvaluateNewRoleState.formData.stepOne.functionCode,
      subFunctionCode: paqEvaluateNewRoleState.formData.stepOne.subFunctionCode,
      requestId: paqEvaluateNewRoleState.requestId,
    };
    appDispatch(getTrAdditionalDetails(trAdditionalDetailsPayload));
  };

  const handleViewForm = () => {
    appDispatch(viewForm());
    appDispatch(goToLastPage(!showLastPage));
  };

  const handleFormLocked = () => {
    let newStatus = formLocked === true ? "Draft" : "Submitted";
    let payload = {
      requestId: paqEvaluateNewRoleState.requestId,
      status: newStatus,
      user: oktaClaims?.gpid,
    };
    appDispatch(updateFormLockStatus(payload));
  };

  const handleNextForTR = () => {
    appDispatch(nextStep());
  };

  const handleSubmitLastPage = () => {
    let payload = {
      evaluatedBy: trEvaluationFormData.evaluatedBy,
      evaluatedLevel: trEvaluationFormData.evaluatedLevelId,
      evaluationMethod: trEvaluationFormData.evaluationMethod,
      futureJobCode: trEvaluationFormData.futureJobCode,
      requestorName: trEvaluationFormData.requestorName,
      snowCaseId: trEvaluationFormData.snowCaseId,
      requestId: paqEvaluateNewRoleState.requestId,
      user: oktaClaims?.gpid,
    };
    //if (validateTrHr(payload)) {
    appDispatch(createTrHr(payload));
    //} else {
    //  handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
    //}
  };

  const handlePreviewAndSubmit = () => {
    handleNextAndSubmit(true, true);
    appDispatch(updatePaqActiveStep(4));
  };

  const onEdit = (stepNumber: number) => {
    appDispatch(updatePaqActiveStep(stepNumber));
    let queryString = window.location.search;
    navigate(
      `/paq-evaluate-new-role${queryString.replace("&showSummary=true", "")}`
    );
  };
  const [isFlag, setIsFlag] = React.useState<boolean>(false);
  //use effect
  React.useEffect(() => {
    if (paqEvaluateNewRoleState.authorize && paqEvaluateNewRoleState.userId) {
      // Update data from query params
      appDispatch(updateReqId(requestId));
      appDispatch(getFormStatus(requestId));

      if (showDraftedValue !== null && showDraftedValue === "yes") {
        //get drafted value
        appDispatch(getDraftedData(requestId));
        setIsFlag(true);
      } else {
        //get pre defined data
        updateFormData(getUserNameFromGpid(paqEvaluateNewRoleState.userId));
        let payload = {
          incumbentGPID: paqEvaluateNewRoleState.userId,
          requestId: requestId,
        };
        updateFormData(getPreDefinedData(payload));
      }
      updateFormData(getPositionDetailsMaster());
      //------------- to be DELETED-----------------------------
      if (userRole !== null && userRole === "tr") {
        appDispatch(updateAccessToLastPage(true));
      }
      // ---------------------------------------------------------
    }
  }, [paqEvaluateNewRoleState.authorize, paqEvaluateNewRoleState.userId]);

  //Get user name from user gpid
  function usePrevious(value: any) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevNewManagerGpid = usePrevious(positonDetailsFormData.newManagerGpid);
  React.useEffect(() => {
    if (paqEvaluateNewRoleState.authorize) {
      if (
        (showDraftedValue == null && showDraftedValue !== "yes") ||
        (prevNewManagerGpid != null &&
          prevNewManagerGpid !== positonDetailsFormData.newManagerGpid)
      ) {
        if (
          positonDetailsFormData.newManagerGpid != null &&
          positonDetailsFormData.newManagerGpid != "" &&
          positonDetailsFormData.newManagerGpid.length >= DEFAULT_GPID_LENGTH
        ) {
          let payload = {
            incumbentGPID: positonDetailsFormData.newManagerGpid,
            requestId: requestId,
            fromManagerGpid: true,
          };
          if (isFlag) {
            updateFormData(
              getUserNameFromGpid(positonDetailsFormData.newManagerGpid)
            );
            updateFormData(getPreDefinedData(payload));
          } else {
            setIsFlag(true);
          }
        }
      }
    }
  }, [
    requestId,
    positonDetailsFormData.newManagerGpid,
    paqEvaluateNewRoleState.authorize,
  ]);

  React.useEffect(() => {
    if (
      paqEvaluateNewRoleState.status === "Draft" &&
      showDraftedValue !== "yes"
    ) {
      // redirect to drafted page
      navigate(
        `/paq-evaluate-new-role?requestId=${requestId}&showDraftedValue=yes`
      );
      window.location.reload();
    }
  });

  const handleExit = () => {
    appDispatch(goToLandingPage());
    let queryString = window.location.search;
    navigate(
      `/paq-evaluate-new-role${queryString.replace("&showSummary=true", "")}`
    );
    window.location.reload();
  };

  return <React.Fragment>
    <Header updateLanguage={updateLanguage} state={paqEvaluateNewRoleState} />
    {isError && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} />}
    {isConfirmModal && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} handleModalSubmit={handleSubmit} isConfirm={true} />}
    {!paqEvaluateNewRoleState.authorizationFailed &&
      !paqEvaluateNewRoleState.authorize ? (
      <div>{getTranslated("alert.checkingAccess") || "Checking access"} ...</div>
    ) : paqEvaluateNewRoleState.authorizationFailed ? (
      <div>{getTranslated("alert.noAccess") || "User do not have access to this form"} !</div>
    ) : userRole === "invalid" ? (
      <div>{getTranslated("alert.unableToAccess") || "Unable to access this form"} !</div>
    ) : paqEvaluateNewRoleState.formStatusFetched === true ? (
      (paqEvaluateNewRoleState.status === "Submitted" || paqEvaluateNewRoleState.status === "Completed" ||
        paqEvaluateNewRoleState.caseId) &&
        userRole !== "tr" ? (
        <div style={{ fontFamily: "initial", textAlign: "center" }}>
          {paqEvaluateNewRoleState.status === "Completed" ? (
            <h3>{getTranslated("alert.alreadyCompleted") || "Form is already completed."}</h3>
          ) : (
            <h3>{getTranslated("alert.formSubmitted") || "Form is successfully submitted."}</h3>
          )}
          <h3>
            {(getTranslated("alert.caseId") || "Case-Id") + " : "}
            {paqEvaluateNewRoleState.status === "Submitted" ||
              paqEvaluateNewRoleState.status === "Completed" ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: paqEvaluateNewRoleState.caseIdFetched,
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: paqEvaluateNewRoleState.caseId,
                }}
              ></span>
            )}
          </h3>
        </div>
      ) : userRole == "tr" && paqEvaluateNewRoleState.trFormSubmitted ? (
        <div style={{ fontFamily: "initial", textAlign: "center" }}>
          {/* "TR Form submit success" */}
          <h3>{getTranslated("toast.formSubmitted") || "Form submitted successfully"}</h3>
        </div>
      ) : (
        <div className="page-wrapper">
          <Container maxWidth="lg" className="container-padding-vertical">
            <div className="form-wrapper">
              {showLandingPage ? (
                <PaqLandingPage handleNextClick={handleLandingPageToForm} />
              ) : showLastPage ? (
                <Grid container spacing={2}>
                  {/*--FORM CONTENT---*/}
                  <Grid item xs={12} md={12} mt={2} className="form-conatiner">
                    {formLoader && (
                      <div className="loader-wrapper">
                        <CircularProgress />
                      </div>
                    )}
                    <TrEvaluationPage updateFormData={updateFormData} />
                  </Grid>
                  {/*--FORM BOTTOM---*/}
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <ButtonsContainer style={{ justifyContent: "right" }}>
                          <ButtonPrimary onClick={() => handleViewForm()}>
                            {getTranslated("buttons.viewForm") || "View Form"}
                          </ButtonPrimary>
                          {formLocked ? (
                            <ButtonPrimary onClick={() => handleFormLocked()}>
                              {getTranslated("buttons.unlockForm") || "Unlock Form"}
                            </ButtonPrimary>
                          ) : (
                            <Button variant="contained" disabled>
                              {getTranslated("buttons.unlockForm") || "Unlock Form"}
                            </Button>
                          )}
                          {!formLocked ? (
                            <ButtonPrimary onClick={() => handleFormLocked()}>
                              {getTranslated("buttons.lockForm") || "Lock Form"}
                            </ButtonPrimary>
                          ) : (
                            <Button variant="contained" disabled>
                              {getTranslated("buttons.lockForm") || "Lock Form"}
                            </Button>
                          )}
                          <ButtonPrimary
                            style={{ marginRight: 0 }}
                            onClick={() => handleSubmitLastPage()}
                          >
                            {/* Save Form */}
                            {getTranslated("buttons.submit") || "Submit"}
                          </ButtonPrimary>
                        </ButtonsContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : showSummary ? (
                <div>
                  {getTranslated("others.reviewQuestionnaire") || "Please review questionnaire responses before submitting."}
                  <Divider style={{ marginTop: "0.5em" }} />
                  {stepNameAndEditButton(0)}
                  <PaqStepOne
                    updateFormData={updateFormData}
                    disabled={showSummary === "true"}
                  />
                  <Divider style={{ marginTop: "1em" }} />
                  {stepNameAndEditButton(1)}
                  <PaqStepTwo
                    updateFormData={updateFormData}
                    disabled={showSummary === "true"}
                  />
                  <Divider style={{ marginTop: "1em" }} />
                  {stepNameAndEditButton(2)}
                  <PaqStepThree
                    updateFormData={updateFormData}
                    disabled={showSummary === "true"}
                  />
                  <Divider style={{ marginTop: "1em" }} />
                  {stepNameAndEditButton(3)}
                  <PaqStepFour
                    updateFormData={updateFormData}
                    disabled={showSummary === "true"}
                  />
                  <Divider style={{ marginTop: "1em" }} />
                  <Grid item xs={12} md={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <LinearProgressWithLabel value={100} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ButtonsContainer style={{ justifyContent: "right" }}>
                          <ButtonSecondary
                            onClick={() => handleExit()}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated("buttons.exit") || "Exit"}
                          </ButtonSecondary>
                          <ButtonPrimary
                            onClick={() => handleConfirmModalOpen(getTranslated("alert.finalStepMessage") || FINAL_STEP_MESSAGE)}
                            style={{ marginRight: 0 }}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {formLoader ? (getTranslated("buttons.submitting") || "Submitting") + "..." : getTranslated("buttons.submit") || "Submit"}
                          </ButtonPrimary>
                          {formLoader && (
                            <CircularProgress
                              size={20}
                              style={{ marginLeft: 10 }}
                            />
                          )}
                        </ButtonsContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <Grid container spacing={2}>
                  {/*--STEPER START---*/}
                  <Grid item xs={12} md={12} mt={2} mb={1}>
                    <Stepper activeStep={paqActiveStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps} style={{ fontSize: 18 }}>
                              {getTranslated(`steps.${index}`)}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Grid>
                  {/*--FORM CONTENT---*/}
                  <Grid item xs={12} md={12} mt={2} className="form-conatiner">
                    {formLoader && (
                      <div className="loader-wrapper">
                        <CircularProgress />
                      </div>
                    )}

                    {stepForm(paqActiveStep)}
                  </Grid>
                  {/*--FORM BOTTOM---*/}
                  <Grid item xs={12} md={12} mt={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <LinearProgressWithLabel value={progress} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ButtonsContainer style={{ justifyContent: "right" }}>
                          {fromSummary && (
                            <ButtonSecondary
                              style={{ marginRight: 0 }}
                              onClick={() => handlePreviewAndSubmit()}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.reviewSubmit') || 'Review & Submit'}
                            </ButtonSecondary>
                          )}
                          {userRole === "tr" ? (
                            <span></span>
                          ) : (
                            <ButtonSecondary
                              onClick={() => handleNextAndSubmit(true)}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.saveDraft') || 'Save Draft'}
                            </ButtonSecondary>
                          )}
                          <ButtonSecondary
                            onClick={() => handleExit()}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated('buttons.exit') || 'Exit'}
                          </ButtonSecondary>
                          {paqActiveStep > 0 ? (
                            <ButtonPrimary
                              onClick={() => handlePrev()}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.prev') || 'Previous'}
                            </ButtonPrimary>
                          ) : (
                            ""
                          )}
                          {hasAccessToLastPage ? (
                            paqActiveStep === steps.length - 1 ? (
                              <ButtonPrimary
                                onClick={() => handleLastNext()}
                                style={{ marginRight: 0 }}
                                disabled={formLoader ? true : false}
                                className={formLoader && "disabledButton"}
                              >
                                {getTranslated('buttons.next') || 'Next'}
                              </ButtonPrimary>
                            ) : (
                              <ButtonPrimary
                                onClick={() => handleNextForTR()}
                                style={{ marginRight: 0 }}
                                disabled={formLoader ? true : false}
                                className={formLoader && "disabledButton"}
                              >
                                {getTranslated('buttons.next') || 'Next'}
                              </ButtonPrimary>
                            )
                          ) : (
                            <ButtonPrimary
                              onClick={() => handleNextAndSubmit()}
                              style={{ marginRight: 0 }}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.next') || 'Next'}
                            </ButtonPrimary>
                          )}
                        </ButtonsContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </Container>
        </div>
      )
    ) : (
      <div></div>
    )}
  </React.Fragment>;
};
export default PaqEvaluateNewRole;
