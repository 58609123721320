import * as React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  getPositionDetailsMaster,
  updatePositionDetailFormData,
  getSubFunctionsDropdown,
  getSectorsDropdown,
  getDivisionsDropdown,
  getRegionsDropdown,
  getBusinessUnitsDropdown,
  getMarketUnitsDropdown,
  getWorkLocationsDropdown,
  updateStepOneMasterData,
} from "../../fmOneSlice";
import FormSelect from "../../../../../app/form-inputs/FormSelect";
import FormText from "../../../../../app/form-inputs/FormText";
import FormDatePicker from "../../../../../app/form-inputs/FormDatePicker";
import { DROPDOWN_OPTIONS_MAP, TOOLTIPS } from "../../../../../app/constants";
import { DEFAULT_GPID_REGEX } from "../../../../../configs/defaults";
import { getTranslated } from "../../../../../app/functions/getTranslatedLevel";
import PurposeOfRequest from "../../../../../app/form-inputs/PurposeOfRequest";

interface IFmPositionDetails {
  updateFormData: Function;
  disabled?: boolean;
}

const FmPositionDetails = ({
  updateFormData,
  disabled = false,
}: IFmPositionDetails) => {
  //States
  const formData = useSelector(
    (state: any) => state.fmEvaluateNewRole.formData.positionDetails
  );
  const state = useSelector(
    (state: any) => state.fmEvaluateNewRole
  );
  const masterData = useSelector(
    (state: any) => state.fmEvaluateNewRole.masterData.positionDetails
  );
  const formLockedFromState = useSelector(
    (state: any) => state.fmEvaluateNewRole.formLocked
  );
  const formLocked = formLockedFromState || disabled;

  //step one dispatch
  const updateState = (payload: object) => {
    updateFormData(updatePositionDetailFormData(payload));
  };
  const updateMasterDataState = (payload: object) => {
    updateFormData(updateStepOneMasterData(payload));
  }

  //removing below dropdown master data
  const updateBelowDropdownOptions = (key: string) => {
    let belowDropdownOption ={}
    DROPDOWN_OPTIONS_MAP[key].map((element: string)=> {
      belowDropdownOption = {
        ...belowDropdownOption,
        [element]: [] 
      }
    })
    updateMasterDataState(belowDropdownOption);
  }

  const getCountriesBasedOnForm = () => { 
    let result: any = [];
    masterData.countries.forEach((element: any) => {
      let formName = formData.requestOfForm.toLowerCase();
      let services = element.services.toLowerCase();
      if (services.includes(formName)) {
        result.push(element)
      }

    }); 
    result.sort(function (a: any, b: any) {
      return a.name.localeCompare(b.name);
    }); 
    return result;
  }
  //On function change
  React.useEffect(() => {
    if (formData.functionCode != null) {
      updateFormData(getSubFunctionsDropdown(formData.functionCode));
    }
  }, [formData.functionCode]);
  //On Country change
  React.useEffect(() => {
    //1. drop down generation
    if (formData.countryCode != null) {
      updateBelowDropdownOptions('countryCode');
      updateFormData(getSectorsDropdown(formData.countryCode));
    }
  }, [formData.countryCode]);
  //On sector change change
  React.useEffect(() => {
    if (formData.sectorCode != null) {
      updateBelowDropdownOptions('sectorCode');
      updateFormData(getDivisionsDropdown(formData.sectorCode));
    }
  }, [formData.sectorCode]);
  //On division  change
  React.useEffect(() => {
    if (formData.divisionCode != null) {
      updateBelowDropdownOptions('divisionCode');
      updateFormData(getRegionsDropdown(formData.divisionCode));
    }
  }, [formData.divisionCode]);
  //On region  change
  React.useEffect(() => {
    if (formData.regionCode != null) {
      updateBelowDropdownOptions('regionCode');
      updateFormData(getBusinessUnitsDropdown(formData.regionCode));
    }
  }, [formData.regionCode]);
  //On buisness unit  change
  React.useEffect(() => {
    if (formData.businessUnitCode != null) {
      updateBelowDropdownOptions('businessUnitCode');
      updateFormData(getMarketUnitsDropdown(formData.businessUnitCode));
    }
  }, [formData.businessUnitCode]);
  //On market unit  change
  React.useEffect(() => {
    if (formData.marketUnitCode != null) {
      updateFormData(getWorkLocationsDropdown(formData.marketUnitCode));
    }
  }, [formData.marketUnitCode]);

  return (
    <>
      <div className="step-one-wrapper">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <PurposeOfRequest
              data={{
                label: getTranslated("step1.purposeOfRequestId") || "Purpose of request",
                value: getTranslated("step1.newJobEvaluation") || "New job evaluation",
                // options: masterData.purposeOfRequests,
                name: "purposeOfRequestId",
                readOnly: true,
                required: true,
                multiline: true,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step1.newManagerGpid") || "New Manager GPID",
                value: formData.newManagerGpid,
                name: "newManagerGpid",
                required: true,
                readOnly: formLocked,
                regex: DEFAULT_GPID_REGEX,
              }}
              updateState={updateState}
              //isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step1.newManagerName") || "New Manager Name",
                value: formData.newManagerName,
                name: "newManagerName",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
              //isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.proposedEvaluatedLevelId") || "Proposed Level",
                value: formData.proposedEvaluatedLevelId,
                options: masterData.proposedLevels,
                name: "proposedEvaluatedLevelId",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            />
            <FormDatePicker
              data={{
                label: getTranslated("step1.effectiveDate") || "Target Effective Date",
                value: formData.effectiveDate,
                name: "effectiveDate",
                readOnly: formLocked,
                required: true,
                toolTipText: getTranslated("tooltips.effectiveDate") || TOOLTIPS.effectiveDate,
                blockOneMonthBack: false,
                blockOneDayBack : true,
              }}
              updateState={updateState}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              data={{
                label: getTranslated("step1.functionCode") || "Function",
                value: formData.functionCode,
                options: masterData.functions,
                name: "functionCode",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.subFunctionCode") || "Sub Function",
                value: formData.subFunctionCode,
                options: masterData.subFunctions,
                name: "subFunctionCode",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step1.proposedJobTitleId") || "New Position Title",
                value: formData.proposedJobTitleId,
                name: "proposedJobTitleId",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
              state={state}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.countryCode") || "Country",
                value: formData.countryCode,
                options: getCountriesBasedOnForm(),
                name: "countryCode",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.sectorCode") || "Sector",
                value: formData.sectorCode,
                options: masterData.sectors,
                name: "sectorCode",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.divisionCode") || "Division",
                value: formData.divisionCode,
                options: masterData.divisions,
                name: "divisionCode",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.regionCode") || "Region",
                value: formData.regionCode,
                options: masterData.regions,
                name: "regionCode",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.businessUnitCode") || "Business Unit",
                value: formData.businessUnitCode,
                options: masterData.businessUnits,
                name: "businessUnitCode",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.marketUnitCode") || "Market Unit",
                value: formData.marketUnitCode,
                options: masterData.marketUnits,
                name: "marketUnitCode",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.workLocationCode") || "Work Location",
                value: formData.workLocationCode,
                options: masterData.workLocations,
                name: "workLocationCode",
                readOnly: formLocked,
                required: true,
                toolTipText: getTranslated("tooltips.workLocationCode") || TOOLTIPS.workLocationCode
              }}
              updateState={updateState}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default FmPositionDetails;
