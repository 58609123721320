import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormControlFlex } from "../styled";
import { ToolTip } from "./ToolTip";
import { DROPDOWN_MAP } from "../constants";
import { getTranslated } from "../functions/getTranslatedLevel";

interface IFormSelect {
  updateState: Function;
  data: any;
  isHidden?:boolean
}

const FormSelect = ({ updateState, data , isHidden=false}: IFormSelect) => {
  /*
  props .data

  {
    label: "Purpose of request",
    value: any value,
    options: [{

    }]
    name:'purpose_of_request',
  }
  updateState = { updateState}
  */

  //local State for error
  const [validationError, setValidationError] = React.useState(false);

  const handleChange = (key: any, event: any) => {
    let value = event.target.value;
    value = value === "TRUE" ? true : value === "FALSE" ? false : value;

    updateState({
      [key]: value,
    });
    if (DROPDOWN_MAP[key]) {
      let belowDropdownValue = {};
      DROPDOWN_MAP[key].map((item: string)=>{
        belowDropdownValue={
          ...belowDropdownValue,
          [item]: null
        }
      })
      updateState(belowDropdownValue);
    }
  };

  React.useEffect(() => {
    if (data.required != undefined && data.required === true) {
      if (
        data.value !== undefined &&
        data.value !== null &&
        data.value !== 0 &&
        data.value !== ""
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
    }
  }, [data]);

  const preparedValue =
    data.value === true ? "TRUE" : data.value === false ? "FALSE" : data.value;

  return (
    <>
      {!isHidden && <FormControlFlex>
        <div className="label">
          <span>
            {data.label}
            {data.required != undefined && data.required === true && (
              <span style={{ color: "white", marginLeft: 5 }}>*</span>
            )}
          </span>
          {/* {data.toolTipText != undefined ? (
            <ToolTip title={data.toolTipText} />
          ) : (
            ""
          )} */}
        </div>

        <div>
          <FormControl fullWidth>
            {data.options != undefined && data.options.length > 0 ? (
              <Select
                size="small"
                id={data.name}
                value={preparedValue}
                label=""
                error={validationError}
                disabled={
                  data.readOnly != undefined && data.readOnly === true
                    ? true
                    : false
                }
                onChange={(e) => handleChange(data.name, e)}
                title={
                  data.options.find((o: any) => {
                    return o.value == preparedValue;
                  })?.name
                }
              >
                <MenuItem disabled value="">
                  <em>{getTranslated("others.select") || "Select"}</em>
                </MenuItem>
                {data.options.map((item: any, i: number) => {
                  return (
                    <MenuItem value={item.value} key={item.name + i}>
                      {getTranslated(`dynamic.${item.name}`) || item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              <Select
                size="small"
                id={data.name}
                value={data.value}
                label=""
                error={validationError}
                disabled={
                  data.readOnly != undefined && data.readOnly === true
                    ? true
                    : false
                }
                onChange={(e) => handleChange(data.name, e)}
              >
                <MenuItem disabled value={0}>
                  <em>{getTranslated("others.select") || "Select"}</em>
                </MenuItem>
              </Select>
            )}
          </FormControl>
        </div>
      </FormControlFlex>}
    </>
  );
};
export default FormSelect;
