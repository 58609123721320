import * as React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  updateStepFourFormData,
  getHierarchyMaster,
  submitCurrentOrganizationChart,
  submitFutureOrganizationChart,
} from "../../paqSlice";
import FormSelect from "../../../../app/form-inputs/FormSelect";
import FormText from "../../../../app/form-inputs/FormText";
import FormNumber from "../../../../app/form-inputs/FormNumber";
import FormAttachment from "../../../../app/form-inputs/FormAttachment";
import { TOOLTIPS, TRUE_FALSE_OPTIONS } from "../../../../app/constants";
import { getTranslated } from "../../../../app/functions/getTranslatedLevel";

interface IPaqStepFour {
  updateFormData: Function;
  disabled?: boolean;
}

const PaqStepFour = ({ updateFormData, disabled = false }: IPaqStepFour) => {
  //States
  const paqState: any = useSelector((state: any) => state.paq);
  const formData: any = useSelector(
    (state: any) => state.paq.formData.stepFour
  );
  const masterData = useSelector((state: any) => state.paq.masterData.stepFour);
  const formLockedFromState = useSelector((state: any) => state.paq.formLocked);
  const formLocked = formLockedFromState || disabled;

  //step one dispatch
  const updateState = (payload: object) => {
    updateFormData(updateStepFourFormData(payload));
  };

  React.useEffect(() => {
    //1. drop down generation
    updateFormData(getHierarchyMaster());
  }, []);

  React.useEffect(() => {}, [formData]);

  return (
    <>
      <div className="step-one-wrapper">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <FormAttachment
              data={{
                state: paqState,
                label: getTranslated("step4.currentStateOrgChartFileName") || "Please upload the current state organization chart",
                value: formData.currentStateOrgChartFileName,
                name: "currentStateOrgChartFileName",
                required: true,
                readOnly: formLocked,
                toolTipText: getTranslated("tooltips.currentStateOrgChartFileName") || TOOLTIPS.currentStateOrgChartFileName,
              }}
              updateState={updateState}
              uploadFile={submitCurrentOrganizationChart}
            />
            <FormAttachment
              data={{
                state: paqState,
                label: getTranslated("step4.futureStateOrgChartFileName") || "Please upload the future state organization chart",
                value: formData.futureStateOrgChartFileName,
                name: "futureStateOrgChartFileName",
                required: true,
                readOnly: formLocked,
                toolTipText: getTranslated("tooltips.futureStateOrgChartFileName") || TOOLTIPS.futureStateOrgChartFileName,
              }}
              updateState={updateState}
              uploadFile={submitFutureOrganizationChart}
            />
            <FormNumber
              data={{
                label: getTranslated("step4.numberOfDirectReports") || "Number of Direct Reports",
                value: formData.numberOfDirectReports,
                name: "numberOfDirectReports",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormNumber
              data={{
                label: getTranslated("step4.numberOfMatrixReport") || "Number of Matrix Reports",
                value: formData.numberOfMatrixReport,
                name: "numberOfMatrixReport",
                readOnly: formLocked,
                required: true,
                toolTipText: getTranslated("tooltips.numberOfMatrixReport") || TOOLTIPS.numberOfMatrixReport,
              }}
              updateState={updateState}
            />
            <FormNumber
              data={{
                label: getTranslated("step4.headCount") || "Total Team Headcount",
                value: formData.headCount,
                name: "headCount",
                readOnly: formLocked,
                required: true,
                toolTipText: getTranslated("tooltips.headCount") || TOOLTIPS.headCount,
              }}
              updateState={updateState}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <FormText
              data={{
                label: getTranslated("step4.incumbentName") || "Employee Name",
                value: formData.incumbentName,
                name: "incumbentName",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step4.incumbentGPID") || "Employee GPID",
                value: formData.incumbentGPID,
                name: "incumbentGPID",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            /> */}
            <FormSelect
              data={{
                label: getTranslated("step4.changeInRelationship") || "Has the role changed reporting relationships ?",
                value: formData.changeInRelationship,
                options: TRUE_FALSE_OPTIONS,
                required: true,
                name: "changeInRelationship",
                readOnly: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step4.managerName") || "Manager Name",
                value: formData.managerName,
                name: "managerName",
                readOnly:
                  formData.changeInRelationship === true && !formLocked
                    ? false
                    : true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step4.managerGPID") || "Manager GPID",
                value: formData.managerGPID,
                name: "managerGPID",
                required: true,
                readOnly:
                  formData.changeInRelationship === true && !formLocked
                    ? false
                    : true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step4.managerEvaluatedLevelId") || "Manager Evaluated Level",
                value: formData.managerEvaluatedLevelId,
                options: masterData.levels,
                name: "managerEvaluatedLevelId",
                readOnly:
                  formData.changeInRelationship === true && !formLocked
                    ? false
                    : true,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step4.matrixManagerName") || "Matrix Manager Name (optional)",
                value: formData.matrixManagerName,
                name: "matrixManagerName",
                readOnly: formLocked,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step4.matrixManagerGPID") || "Matrix Manager GPID (optional)",
                value: formData.matrixManagerGPID,
                name: "matrixManagerGPID",
                readOnly: formLocked,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step4.hrManagerName") || "HRBP / HR Manager Name (optional)",
                value: formData.hrManagerName,
                name: "hrManagerName",
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step4.hrManagerGPID") || "HRBP / HR Manager GPID (optional)",
                value: formData.hrManagerGPID,
                name: "hrManagerGPID",
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PaqStepFour;
