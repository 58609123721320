import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  findLevelObjByName,
  successNotice,
  errorNotice,
  showCaseId,
  warningNotice,
  getProposedCompensatedLevelsArray,
  sendEmail,
} from "../../../app/functions/CommonFunctions";
import {
  fetchDraftedData,
  fetchPreDefinedData,
  fetchPreDefinedDataPositionId,
  fetchPositionDetailsMaster,
  postPositionDetails,
  fetchSubFunctionsDropdown,
  fetchSectorsDropdown,
  fetchDivisionsDropdown,
  fetchRegionsDropdown,
  fetchBusinessUnitsDropdown,
  fetchMarketUnitsDropdown,
  fetchWorkLocationsDropdown,
  postRoleContext,
  fetchRoleContextForm,
  fetchFmDraftedData,
  postLockUnlockStatus,
  fetchTrAdditionalDetails,
  postGeneratePdf,
  postTrHr,
  fetchManagerName,
  fetchFormStatus,
  fetchQueryParams,
  fetchUserNameFromGpid,
  fetchFutureJobCode,
  uploadCurrentOrganizationChart,
  uploadFutureOrganizationChart,
  fetchRoleContextMaster,
} from "./fmOneAPI";
import { StringForFileUpload } from "../../../app/constants";
import { getTranslated } from "../../../app/functions/getTranslatedLevel";

//*** INITIAL STATE ****
const initialState = {
  stepOneEditable: true,
  requestOfForm: "",
  authorize: false,
  authorizationFailed: false,
  fmActiveStep: 0,
  fmProgress: 50,
  clickedOnDraft: true,
  isReviewAndSubmit: false,
  formLoader: false,
  formLoaderEC: false,
  trFormSubmitted: false,
  formLocked: false,
  hasAccessToLastPage: false,
  showLastPage: false,
  showLandingPage: true,
  futureJobCodeReadonly: false,
  pdfGenerated: false,
  fromSummary: false,
  requestId: "",
  gpId: null,
  status: "",
  userId: "",
  oktaUserData: null,
  oktaUserRole: null,
  comments: "",
  caseId: "",
  formStatusFetched: false,
  caseIdFetched: "",
  language: 'en',
  masterData: {
    positionDetails: {
      purposeOfRequests: [],
      currentEvaluatedLevels: [],
      currentCompensatedLevels: [],
      proposedLevels: [],
      functions: [],
      subFunctions: [],
      jobTitles: [],
      countries: [],
      sectors: [],
      divisions: [],
      regions: [],
      businessUnits: [],
      marketUnits: [],
      workLocations: [],
      proposedCompensatedLevels: [],
    },
    roleContext: {
      businessContexts: [],
      orgLayers: []
    },
  },
  formData: {
    positionDetails: {
      purposeOfRequestId: "",
      requestOfForm: "",
      newManagerName: null,
      newManagerGpid: null,
      currentPositionId: null,
      currentEvaluatedLevelId: null,
      proposedEvaluatedLevelId: null,
      proposedCompensatedLevelId: null,
      effectiveDate: "", //moment(new Date()).format("MMMM D,Y"),
      functionCode: null,
      subFunctionCode: null,
      jobTitleId: null,
      proposedJobTitleId: null,
      countryCode: null,
      sectorCode: null,
      divisionCode: null,
      regionCode: null,
      businessUnitCode: null,
      marketUnitCode: null,
      workLocationCode: null,
    },
    roleContext: [],
    roleContextStaticFields: {
      organisationLayerId: null,
      totalBeverageVolume: 0,
      nopbt: 0,
      systemNetRevenue: 0,
      comparableRoles: "",
      rolePurpose: "",
      businessContextForRoleReevaluationId: null,
      otherDescription: null,
      currentStateOrgChartFileName: "",
      futureStateOrgChartFileName: "",
      numberOfDirectReports: 0,
    },
    trEvaluationStep: {
      submittedDate: "",
      requestorName: "",
      requestSubmissionDate: "NO_API",
      snowCaseId: "",
      evaluatedLevel: null,
      futureJobCode: "",
      evaluationMethod: "Functional Model",
      evaluatedBy: "Automatic",
    },
  },
  roleContextForm: [],
  disableOptionsArray: [
    { selectedAnswerId: 102, disabledAnswerIds: [107, 108, 111, 112] },
    { selectedAnswerId: 104, disabledAnswerIds: [105, 106, 109, 110] },
  ],
  oneOfTheseOptionsArray: [
    { selectedAnswerId: 105, disabledAnswerIds: [109, 110] },
    { selectedAnswerId: 106, disabledAnswerIds: [109, 110] },
    { selectedAnswerId: 107, disabledAnswerIds: [111, 112] },
    { selectedAnswerId: 108, disabledAnswerIds: [111, 112] },

    { selectedAnswerId: 109, disabledAnswerIds: [105, 106] },
    { selectedAnswerId: 110, disabledAnswerIds: [105, 106] },
    { selectedAnswerId: 111, disabledAnswerIds: [107, 108] },
    { selectedAnswerId: 112, disabledAnswerIds: [107, 108] },
  ],
  checkBoxQuestions: [] as any,
};

//Aysnc methods
export const getDraftedData = createAsyncThunk(
  "fmReEvaluate/getDraftedData",
  async (requestId: any) => {
    const response = await fetchDraftedData(requestId);
    let payload = {
      positionDetails: response.positionDetails,
    };
    return payload;
  }
);
export const getFmDraftedData = createAsyncThunk(
  "fmReEvaluate/getFmDraftedData",
  async (requestId: any) => {
    const response = await fetchFmDraftedData(requestId);
    let payload = {
      roleContext: response,
    };
    return payload;
  }
);

//1. For prepopulated data first time
export const getPreDefinedData = createAsyncThunk(
  "fmReEvaluate/getPreDefinedData",
  async (funPayload: any) => {
    const response = await fetchPreDefinedData(
      funPayload.incumbentGPID,
      funPayload.requestId
    );
    //Return null as payload if response null
    if (response.positionDetailsPrePopulated == null) {
      return null;
    }

    let positionDetails = response.positionDetailsPrePopulated;
    let currentJobTitle = response.currentJobTitle;
    let payload = {
      positionDetails: {
        countryCode: positionDetails.country,
        sectorCode: positionDetails.sector,
        divisionCode: positionDetails.division,
        regionCode: positionDetails.region,
        businessUnitCode: positionDetails.businessUnit,
        marketUnitCode: positionDetails.marketUnit,
        workLocationCode: positionDetails.workLocation,
        newManagerGpid: positionDetails.incumbentGPID
      },
      currentJobTitle: currentJobTitle
    };

    //update function sub function based on new manager gpid
    if (funPayload.populateAll != undefined && funPayload.populateAll) {
      payload.positionDetails = {
        ...payload.positionDetails,
        ...{
          currentEvaluatedLevelId: null, //This only for paq 2 new manager gpid should not populate
          functionCode: positionDetails.function,
          subFunctionCode: positionDetails.subFunction,
        },
      };
    }

    return payload;
  }
);

export const getCurrentJobTitleFromEC = createAsyncThunk(
  "paq/getCurrentJobTitleFromEC",
  async (funPayload: any) => {
    const response = await fetchPreDefinedData(
      funPayload.incumbentGPID,
      funPayload.requestId
    );
    let payload = response.currentJobTitle
    return payload;
  }
);

//1. FOR POSITION DETAILS
export const getPositionDetailsMaster = createAsyncThunk(
  "fmReEvaluate/getPositionDetailsMaster",
  async () => {
    const response = await fetchPositionDetailsMaster();
    //create payload to update
    let updatePayload = {
      positionDetails: {
        purposeOfRequests: response.requestPurposeList,
        currentEvaluatedLevels: response.levelsList,
        currentCompensatedLevels: response.levelsList,
        proposedLevels: response.levelsList,
        functions: response.functionsList,
        jobTitles: response.jobTitlesList,
        countries: response.countriesList,
      },
    };
    return updatePayload;
  }
);

export const getSubFunctionsDropdown = createAsyncThunk(
  "fmReEvaluate/getSubFunctionsDropdown",
  async (functionCode: any) => {
    const response = await fetchSubFunctionsDropdown(functionCode);
    return response;
  }
);

export const submitPositionDetails = createAsyncThunk(
  "fmReEvaluate/submitPositionDetails",
  async (payload: any) => {
    const response = await postPositionDetails(payload);
    return response;
  }
);
// save position details
export const savePositionDetails = createAsyncThunk(
  "fmReEvaluate/savePositionDetails",
  async (payload: any) => {
    const response = await postPositionDetails(payload);
    return response;
  }
);

export const getSectorsDropdown = createAsyncThunk(
  "fmReEvaluate/getSectorsDropdown",
  async (countryCode: any) => {
    const response = await fetchSectorsDropdown(countryCode);
    return response;
  }
);

export const getDivisionsDropdown = createAsyncThunk(
  "fmReEvaluate/getDivisionsDropdown",
  async (sectorCode: any) => {
    const response = await fetchDivisionsDropdown(sectorCode);
    return response;
  }
);

export const getRegionsDropdown = createAsyncThunk(
  "fmReEvaluate/getRegionsDropdown",
  async (sectorCode: any) => {
    const response = await fetchRegionsDropdown(sectorCode);
    return response;
  }
);

export const getBusinessUnitsDropdown = createAsyncThunk(
  "fmReEvaluate/getBusinessUnitsDropdown",
  async (regionCode: any) => {
    const response = await fetchBusinessUnitsDropdown(regionCode);
    return response;
  }
);

export const getMarketUnitsDropdown = createAsyncThunk(
  "fmReEvaluate/getMarketUnitsDropdown",
  async (businessUnitCode: any) => {
    const response = await fetchMarketUnitsDropdown(businessUnitCode);
    return response;
  }
);

export const getWorkLocationsDropdown = createAsyncThunk(
  "fmReEvaluate/getWorkLocationsDropdown",
  async (marketUnitCode: any) => {
    const response = await fetchWorkLocationsDropdown(marketUnitCode);
    return response;
  }
);

//2. FOR ROLE CONTEXT
export const getRoleContextMaster = createAsyncThunk(
  "fmReEvaluate/getRoleContextMaster",
  async () => {
    const response = await fetchRoleContextMaster();
    //create payload to update
    let updatePayload = {
      businessContexts: response.businessContextsList,
      orgLayers: response.orgLayerList
    };
    return updatePayload;
  }
);
export const submitRoleContext = createAsyncThunk(
  "fmReEvaluate/submitRoleContext",
  async (payload: any) => {
    const response = await postRoleContext(
      payload.insertPayload,
      payload.fmType
    );
    return response;
  }
);

export const getRoleContextForm = createAsyncThunk(
  "fmReEvaluate/getRoleContextForm",
  async (fmType: any) => {
    const payload = await fetchRoleContextForm(fmType);
    return payload;
  }
);
//organization chart upload
export const submitCurrentOrganizationChart = createAsyncThunk(
  "fmReEvaluate/submitCurrentOrganizationChart",
  async (payload: any) => {
    const response = await uploadCurrentOrganizationChart(payload);
    return response;
  }
);
export const submitFutureOrganizationChart = createAsyncThunk(
  "fmReEvaluate/submitFutureOrganizationChart",
  async (payload: any) => {
    const response = await uploadFutureOrganizationChart(payload);
    return response;
  }
);

// for TR Evaluation
export const getFutureJobCode = createAsyncThunk(
  "fmReEvaluate/getFutureJobCode",
  async (futureJobCodePayload: any) => {
    const response = await fetchFutureJobCode(
      futureJobCodePayload.functionCode,
      futureJobCodePayload.subFunctionCode,
      futureJobCodePayload.evaluatedLevelId,
      futureJobCodePayload.requestId
    );
    return response;
  }
);

// chenge Lock / Unlock status
export const updateFormLockStatus = createAsyncThunk(
  "fmReEvaluate/updateFormLockStatus",
  async (payload: any) => {
    const response = await postLockUnlockStatus(payload);
    return response;
  }
);
// for TR Additional Details
export const getTrAdditionalDetails = createAsyncThunk(
  "fmReEvaluate/getTrAdditionalDetails",
  async (trAdditionalDetailsPayload: any) => {
    const response = await fetchTrAdditionalDetails(
      trAdditionalDetailsPayload.functionCode,
      trAdditionalDetailsPayload.subFunctionCode,
      trAdditionalDetailsPayload.requestId
    );
    return response;
  }
);
// generate PDF
export const generatePdf = createAsyncThunk(
  "fmReEvaluate/generatePdf",
  async (payload: any) => {
    const response = await postGeneratePdf(payload);
    return response;
  }
);
// save TR details
export const createTrHr = createAsyncThunk(
  "fmReEvaluate/createTrHr",
  async (payload: any) => {
    const response = await postTrHr(payload);
    return response;
  }
);
// Submit FM
export const submitFM = createAsyncThunk(
  "fmReEvaluate/submitFM",
  async (payload: any) => {
    const response = await postRoleContext(
      payload.insertPayload,
      payload.fmType
    );
    return response;
  }
);
// get manager Name
export const getManagerName = createAsyncThunk(
  "fmReEvaluate/getManagerName",
  async (gpid: any) => {
    const response = await fetchManagerName(gpid);
    return response;
  }
);
// get form status
export const getFormStatus = createAsyncThunk(
  "fmReEvaluate/getFormStatus",
  async (requestId: any) => {
    const response = await fetchFormStatus(requestId);
    return response;
  }
);

// get query params from api
export const getQueryParams = createAsyncThunk(
  "fmReEvaluate/getQueryParams",
  async (payload: any) => {
    const response = await fetchQueryParams(payload);
    return response;
  }
);

// using position id
export const getPreDefinedDataPositionId = createAsyncThunk(
  "fmReEvaluate/getPreDefinedDataPositionId",
  async (funPayload: any) => {
    const response = await fetchPreDefinedDataPositionId(
      funPayload.positionId,
      funPayload.requestId
    );
    let positionDetails = response.positionDetailsPrePopulated;
    let currentJobTitle = response.currentJobTitle;
    let payload: any = { stepOne: null, currentJobTitle: currentJobTitle };
    if (positionDetails !== null) {
      payload["stepOne"] = {
        currentEvaluatedLevelId: positionDetails.currentEvaluatedLevel,
        jobTitleId: positionDetails.jobTitle,
        countryCode: positionDetails.country,
        sectorCode: positionDetails.sector,
        divisionCode: positionDetails.division,
        regionCode: positionDetails.region,
        businessUnitCode: positionDetails.businessUnit,
        marketUnitCode: positionDetails.marketUnit,
        workLocationCode: positionDetails.workLocation,
      };
    }
    return payload;
  }
);

//get user name from GPID
export const getUserNameFromGpid = createAsyncThunk(
  "fmReEvaluate/getUserNameFromGpid",
  async (gpid: any) => {
    const response = await fetchUserNameFromGpid(gpid);
    let payload: any = { stepOne: null };
    payload["stepOne"] = {
      newManagerName: response.managerName,
    };
    return payload;
  }
);

//*** CREATE SLICE */
export const fmReEvaluateSlice: any = createSlice({
  name: "fmReEvaluate",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.fmActiveStep += 1;
      state.fmProgress += 50;
    },
    prevStep: (state) => {
      state.fmActiveStep -= 1;
      state.fmProgress -= 50;
    },
    goToLastPage: (state, action: PayloadAction<any>) => {
      state.showLastPage = action.payload;
    },
    goFromLandingPageToForm: (state) => {
      state.showLandingPage = false;
      state.fmActiveStep = 0;
      state.fmProgress = 50;
    },
    goToLandingPage: (state) => {
      state.showLandingPage = true;
    },
    viewForm: (state) => {
      state.fmActiveStep = 0;
      state.fmProgress = 50;
    },
    //update reqId
    updateReqId: (state, action: PayloadAction<any>) => {
      state.requestId = action.payload;
    },
    updateUserId: (state, action: PayloadAction<any>) => {
      state.userId = action.payload;
    },
    updateOktaUserData: (state, action: PayloadAction<any>) => {
      state.oktaUserData = action.payload;
    },
    updateOktaUserRole: (state, action: PayloadAction<any>) => {
      state.oktaUserRole = action.payload;
    },
    updatePurposeOfRequestId: (state, action: PayloadAction<any>) => {
      state.formData.positionDetails.purposeOfRequestId = action.payload;
    },
    updateProposedEvaluatedLevelId: (state, action: PayloadAction<any>) => {
      state.gpId = action.payload;
      state.formData.positionDetails.proposedEvaluatedLevelId = action.payload;
      state.formData.positionDetails.proposedCompensatedLevelId =
        action.payload;
    },
    updateFunctionCode: (state, action: PayloadAction<any>) => {
      state.formData.positionDetails.functionCode = action.payload;
    },
    updateSubFunctionCode: (state, action: PayloadAction<any>) => {
      state.formData.positionDetails.subFunctionCode = action.payload;
    },
    // maybe need to delete this
    updateFormStatusFetched: (state, action: PayloadAction<any>) => {
      state.formStatusFetched = action.payload;
    },
    //Update master data
    updateStepOneMasterData: (state, action: PayloadAction<any>) => {
      state.masterData.positionDetails = {
        ...state.masterData.positionDetails,
        ...action.payload,
      };
    },

    //Update form data
    updatePositionDetailFormData: (state, action: PayloadAction<any>) => {
      // {name:"souvik"}
      state.formData.positionDetails = {
        ...state.formData.positionDetails,
        ...action.payload,
      };
    },
    //update role context static data
    updateRoleContextStaticFormData: (state, action: PayloadAction<any>) => {
      state.formData.roleContextStaticFields = {
        ...state.formData.roleContextStaticFields,
        ...action.payload,
      };
    },
    updateRoleContextFormData: (state: any, action: PayloadAction<any>) => {
      // -------------- for radio button -----------------
      if (!state.checkBoxQuestions.includes(action.payload.criteriaId)) {
        let new_array = state.formData.roleContext.map((element: any) =>
          element.criteriaId === action.payload.criteriaId
            ? { ...element, answerId: action.payload.answerId }
            : element
        );
        state.formData.roleContext = new_array;
      }
      // ---------------- for checkBox ---------------------
      else {
        let answerIdArray = [] as any;
        let newArray = state.formData.roleContext;
        state.formData.roleContext.forEach((data: any) => {
          if (data.criteriaId === action.payload.criteriaId) {
            answerIdArray.push(data.answerId);
          }
        });
        if (answerIdArray[0] === 0) {
          let objIndex = newArray.findIndex(
            (obj: any) => obj.criteriaId === action.payload.criteriaId
          );
          newArray[objIndex].answerId = action.payload.answerId;
          state.formData.roleContext = newArray;
        } else {
          if (answerIdArray.includes(action.payload.answerId)) {
            answerIdArray = answerIdArray.filter(
              (data: any) => data !== action.payload.answerId
            );
            newArray = newArray.filter((data: any) =>
              data.criteriaId === action.payload.criteriaId &&
                data.answerId === action.payload.answerId
                ? false
                : true
            );
            if (!answerIdArray.length) {
              let objToInsert = {
                criteriaId: action.payload.criteriaId,
                answerId: 0,
              };
              newArray = [...newArray, objToInsert];
            }
            state.formData.roleContext = newArray;
          } else {
            newArray = [...newArray, action.payload];
            state.formData.roleContext = newArray;
          }
        }
      }
    },
    updateTrEvaluationStepFormData: (state, action: PayloadAction<any>) => {
      // {name:"souvik"}
      state.formData.trEvaluationStep = {
        ...state.formData.trEvaluationStep,
        ...action.payload,
      };
    },
    updateFormLocked: (state, action: PayloadAction<any>) => {
      state.formLocked = action.payload;
    },
    // ------------------------- to be deleted --------------------
    updateAccessToLastPage: (state, action: PayloadAction<any>) => {
      state.hasAccessToLastPage = action.payload;
    },
    // update role context comments
    updateComments: (state, action: PayloadAction<any>) => {
      state.comments = action.payload.comments;
    },
    updateAuthorize: (state, action: PayloadAction<any>) => {
      state.authorize = action.payload;
    },
    updateFmActiveStep: (state, action: PayloadAction<any>) => {
      state.fromSummary = true;
      state.fmActiveStep = action.payload;
      state.fmProgress = (action.payload + 1) * 50;
    },
    updateClickedOnDraft: (state, action: PayloadAction<boolean>) => {
      state.clickedOnDraft = action.payload;
    },
    updateIsReviewAndSubmit: (state, action: PayloadAction<boolean>) => {
      state.isReviewAndSubmit = action.payload;
    },
    skipLandingPage: (state) => {
      state.showLandingPage = false;
    },
    updateStepNumber: (state, action: PayloadAction<any>) => {
      state.fmActiveStep = action.payload;
      state.fmProgress = (action.payload + 1) * 50;
    },
    updateLanguage: (state, action) => {
      state.language = action.payload.language
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      //1 Drafted data fetch
      .addCase(getDraftedData.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getDraftedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          //Update stepOne state
          if (payload.positionDetails !== null) {
            state.stepOneEditable = false;
            payload.positionDetails.effectiveDate = moment(
              new Date(payload.positionDetails.effectiveDate)
            ).format("MMMM D,Y");
            // update submitted date for trEvaluationStep
            state.formData.trEvaluationStep = {
              ...state.formData.trEvaluationStep,
              submittedDate: moment(
                new Date(payload.positionDetails.submittedDate)
              ).format("MMMM D,Y"),
            };
            state.formData.positionDetails = {
              ...state.formData.positionDetails,
              ...payload.positionDetails,
            };
            state.status = payload.positionDetails.status;
            state.formLocked =
              payload.positionDetails?.status === "Submitted" || payload.positionDetails?.status === "Completed" ? true : false;
          }

          //Loader
          state.formLoader = false;
        }
      )
      .addCase(getDraftedData.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch drafted data api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.cannotFetchDrafted') || 'Cannot fetch drafted data, please try after sometime'
        );
      })
      .addCase(getFmDraftedData.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getFmDraftedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;
          //Update stepOne state

          if (payload.roleContext !== null && payload.roleContext.answer) {
            state.formData.roleContext = payload.roleContext.answer;
            state.comments = payload.roleContext.comments;
            state.formData.roleContextStaticFields = payload.roleContext.staticFileds;
          }
          //Loader
          state.formLoader = false;
        }
      )
      .addCase(getFmDraftedData.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch drafted data api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.cannotFetchDrafted') || 'Cannot fetch drafted data, please try after sometime'
        );
      })
      //1 prepopulated data fetch
      .addCase(getPreDefinedData.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getPreDefinedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload != null) {
            let payload = action.payload;
            //Update stepOne state
            if (payload.positionDetails !== null) {
              state.stepOneEditable = false;
              //StepOne level set
              if (
                payload.positionDetails.currentEvaluatedLevelId &&
                payload.positionDetails.currentEvaluatedLevelId != null
              ) {
                payload.positionDetails.currentEvaluatedLevelId =
                  findLevelObjByName(
                    payload.positionDetails.currentEvaluatedLevelId,
                    state.masterData.positionDetails.currentEvaluatedLevels
                  ).value;
              }
              state.formData.positionDetails = {
                ...state.formData.positionDetails,
                ...payload.positionDetails,
              };
            } else {
              let makeNull = {
                currentEvaluatedLevelId: null,
                jobTitleId: null,
                countryCode: null,
                sectorCode: null,
                divisionCode: null,
                regionCode: null,
                businessUnitCode: null,
                marketUnitCode: null,
                workLocationCode: null,
              };
              state.stepOneEditable = true;

              //Update stepOne state
              state.formData.positionDetails = {
                ...state.formData.positionDetails,
                ...makeNull,
              };
            }
            if (state.language == 'es' && payload.currentJobTitle && payload.currentJobTitle.nameEsMx)
              state.formData.positionDetails.jobTitleId = payload.currentJobTitle.nameEsMx
          }
          //Loader
          state.formLoader = false;
        }
      )
      .addCase(getPreDefinedData.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch prepopulated data api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.cannotFetchECData') || 'Cannot fetch prepopulated EC data, please try after sometime'
        );
      })
      .addCase(getCurrentJobTitleFromEC.pending, (state) => {
        state.formLoaderEC = true;
      })
      .addCase(
        getCurrentJobTitleFromEC.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          if (state.language == 'es' && payload?.nameEsMx)
            state.formData.positionDetails.jobTitleId = payload.nameEsMx
          if (state.language == 'en' && payload && payload?.nameDefaultValue)
            state.formData.positionDetails.jobTitleId = payload.nameDefaultValue
          //Loader
          state.formLoaderEC = false;
        }
      )
      //1. FOR POSITION DETAIL
      //1.1 master data fetch
      .addCase(getPositionDetailsMaster.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getPositionDetailsMaster.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails = {
            ...state.masterData.positionDetails,
            ...action.payload.positionDetails,
          };
          // update proposedCompensatedLevels array
          const proposedCompensatedLevelsArray: any =
            getProposedCompensatedLevelsArray(
              action.payload.positionDetails.proposedLevels,
              state.formData.positionDetails.proposedEvaluatedLevelId
            );
          state.masterData.positionDetails = {
            ...state.masterData.positionDetails,
            proposedCompensatedLevels: proposedCompensatedLevelsArray,
          };
          state.formLoader = false;
        }
      )
      .addCase(getPositionDetailsMaster.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch position details master api");
      }) //1.3 subfunction data fetch
      .addCase(getSubFunctionsDropdown.pending, (state) => {
        // state.formLoader = true;
      })
      .addCase(
        getSubFunctionsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails.subFunctions = action.payload;
          // state.formLoader = false;
        }
      )
      .addCase(getSubFunctionsDropdown.rejected, (state) => {
        // state.formLoader = false;
        console.log("Error is happen in fetch subFunctions details master api");
      })
      //1.4 submit form
      .addCase(submitPositionDetails.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitPositionDetails.fulfilled, (state, action) => {
        state.fmActiveStep += 1;
        state.fmProgress += 50;
        state.formLoader = false;
        //show notice
        successNotice(
          "Success !!",
          "Position details have been saved successfully"
        );
      })
      .addCase(submitPositionDetails.rejected, (state) => {
        // state.paqActiveStep += 1; //NEED TO REMOVE IN FUTURE
        state.formLoader = false;
        console.log("Error is happen in submit position details api");
        //Show error notice
        errorNotice(
          `${getTranslated('toast.ErrorSubmitPositionDetails') || 'Error in submit position details'}!!`,
          getTranslated('toast.somethingWentWrong') || 'Something went wrong, please try after sometime'
        );
      })
      // save position details
      .addCase(savePositionDetails.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(savePositionDetails.fulfilled, (state, action) => {
        state.formLoader = false;
        //**Send email only when "save as draft"
        if (
          state.oktaUserData !== null &&
          state.oktaUserRole !== "tr" &&
          state.clickedOnDraft && !state.isReviewAndSubmit
        ) {
          sendEmail({
            userName: state.oktaUserData["FirstName"],
            userEmail: state.oktaUserData["email"],
            requestId: state.requestId,
            step: state.fmActiveStep,
            language: state.language
          });
        }
        if (state.clickedOnDraft) {
          successNotice(
            `${getTranslated('toast.success') || 'Success'} !!`,
            getTranslated('toast.QuestionnaireSaved') || 'Job Evaluation Questionnaire saved successfully'
          );
        } else {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.positionDetailsSaved') || 'Position details have been saved successfully'
          );
        }
      })
      .addCase(savePositionDetails.rejected, (state) => {
        state.formLoader = false;
        //Show error notice
        errorNotice(
          `${getTranslated('toast.ErrorSubmitPositionDetails') || 'Error in submit position details'}!!`,
          getTranslated('toast.somethingWentWrong') || 'Something went wrong, please try after sometime'
        );
      })
      //1.3 sectors data fetch
      .addCase(getSectorsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getSectorsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails.sectors = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getSectorsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch sector details master api");
      })
      //1.5 Divisions data fetch
      .addCase(getDivisionsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getDivisionsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails.divisions = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getDivisionsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch divition details master api");
      })
      //1.5 regions data fetch
      .addCase(getRegionsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getRegionsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails.regions = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getRegionsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch regions details master api");
      })
      //1.6 buisness data fetch
      .addCase(getBusinessUnitsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getBusinessUnitsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails.businessUnits = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getBusinessUnitsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log(
          "Error is happen in fetch buisness unit details master api"
        );
      })
      //1.7 marketunit data fetch
      .addCase(getMarketUnitsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getMarketUnitsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails.marketUnits = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getMarketUnitsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch marketUnits details master api");
      })
      //1.8 work locations data fetch
      .addCase(getWorkLocationsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getWorkLocationsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.positionDetails.workLocations = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getWorkLocationsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log(
          "Error is happen in fetch workLocations details master api"
        );
      })
      .addCase(getRoleContextForm.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getRoleContextForm.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          // Get Form
          state.roleContextForm = payload;

          // initialte form fields
          let roleContextArray: any = [];
          let checkBoxQuestionsArray = [] as any;
          if (payload) {
            payload.forEach((element: any) => {
              element.subFramework.forEach((sub: any) => {
                sub.criterias.forEach((criteria: any) => {
                  if (criteria.answerType === "Radio") {
                    roleContextArray.push({
                      criteriaId: criteria.criteriaCode,
                      answerId: criteria.options[0].optionCode,
                    });
                  } else {
                    roleContextArray.push({
                      criteriaId: criteria.criteriaCode,
                      answerId: 0,
                    });
                    checkBoxQuestionsArray.push(criteria.criteriaCode);
                    state.checkBoxQuestions = checkBoxQuestionsArray;
                  }
                });
              });
            });
            // for FM-2 select only 1 from 2 radio questions
            let count = 0;
            roleContextArray.forEach((element: any) => {
              state.oneOfTheseOptionsArray.forEach((option: any) => {
                if (element.answerId === option.selectedAnswerId) {
                  if (count === 0) {
                    count++;
                  } else {
                    let index = roleContextArray.findIndex(
                      (answer: any) => answer.criteriaId === element.criteriaId
                    );
                    roleContextArray[index].answerId = 0;
                  }
                }
              });
            });
          }
          state.formData.roleContext = roleContextArray;
          //Loader
          // state.formLoader = false;
        }
      )
      .addCase(getRoleContextForm.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch prepopulated data api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.cannotFetchECData') || 'Cannot fetch prepopulated EC data, please try after sometime'
        );
      })
      //2.1 master data fetch
      .addCase(getRoleContextMaster.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getRoleContextMaster.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.roleContext = {
            ...state.masterData.roleContext,
            ...action.payload,
          };
          state.formLoader = false;
        }
      )
      .addCase(getRoleContextMaster.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch role context  master api");
      })
      .addCase(submitRoleContext.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitRoleContext.fulfilled, (state, action) => {
        if (state.clickedOnDraft === false) {
          state.fmActiveStep += 1;
        }
        state.formLoader = false;
        //**Send email only when "save as draft"
        if (
          state.oktaUserData !== null &&
          state.oktaUserRole !== "tr" &&
          state.clickedOnDraft && !state.isReviewAndSubmit
        ) {
          sendEmail({
            userName: state.oktaUserData["FirstName"],
            userEmail: state.oktaUserData["email"],
            requestId: state.requestId,
            step: state.fmActiveStep,
            language: state.language
          });
        }
        //show notice
        if (state.clickedOnDraft) {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.QuestionnaireSaved') || 'Job Evaluation Questionnaire saved successfully'
          );
        } else {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.roleContextSaved') || 'Role Context has been successfully saved'
          );
        }
      })
      .addCase(submitRoleContext.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit Role Context api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWrongRoleContext') || "Something went wrong to save 'Role Context', please try after sometime"
        );
      })
      // upload current org chart
      .addCase(submitCurrentOrganizationChart.pending, (state) => {
        // state.formLoader = true;
      })
      .addCase(submitCurrentOrganizationChart.fulfilled, (state, action) => {
        state.formLoader = false;
        console.log("called")
        state.formData.roleContextStaticFields.currentStateOrgChartFileName =
          StringForFileUpload;
        console.log("called")
        //show notice
        successNotice(
          `${getTranslated('toast.success') || 'Success'}!!`,
          getTranslated('toast.currentChartUploaded') || 'Current Organization Chart Uploaded Successfully'
        );
      })
      .addCase(submitCurrentOrganizationChart.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit current org chert api");
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.unableUploadCurrentChart') || 'Unable to Upload Current Organization Chart, please try again'
        );
      })
      // upload future org chart
      .addCase(submitFutureOrganizationChart.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitFutureOrganizationChart.fulfilled, (state, action) => {
        state.formLoader = false;
        state.formData.roleContextStaticFields.futureStateOrgChartFileName =
          StringForFileUpload;
        //show notice
        successNotice(
          `${getTranslated('toast.success') || 'Success'}!!`,
          getTranslated('toast.futureChartUploaded') || 'Future Organization Chart Uploaded Successfully'
        );
      })
      .addCase(submitFutureOrganizationChart.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit future org chert api");
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.unableUploadFutureChart') || 'Unable to Upload Future Organization Chart, please try again'
        );
      })
      // get future job code
      .addCase(getFutureJobCode.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getFutureJobCode.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (
            action.payload.futureJobCode !== null &&
            action.payload.futureJobCode !== "" &&
            action.payload.futureJobCode !== undefined
          ) {
            state.futureJobCodeReadonly = true;
          } else {
            state.futureJobCodeReadonly = false;
          }
          state.formData.trEvaluationStep = {
            ...state.formData.trEvaluationStep,
            futureJobCode:
              action.payload.futureJobCode === null
                ? ""
                : action.payload.futureJobCode,
          };
          state.formLoader = false;
        }
      )
      .addCase(getFutureJobCode.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.errorFetchingFutureJobCode') || 'Error while fetching Future Job Code'} !`
        );
        console.log("Error while fetching Future Job Code !");
      })
      // change form status Draft/Submitted
      .addCase(updateFormLockStatus.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(updateFormLockStatus.fulfilled, (state, action) => {
        state.formLoader = false;
        // updateFormLocked(!state.formLocked);
        let formLockedOld = state.formLocked;
        state.formLocked = !formLockedOld;
      })
      .addCase(updateFormLockStatus.rejected, (state) => {
        state.formLoader = false;
        //Show error notice
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWentWrong') || 'Something went wrong, please try after sometime'
        );
      })
      // get Tr Additional Details
      .addCase(getTrAdditionalDetails.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getTrAdditionalDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (
            action.payload.futureJobCode !== null &&
            action.payload.futureJobCode !== "" &&
            action.payload.futureJobCode !== undefined
          ) {
            state.futureJobCodeReadonly = true;
          }
          if (!state.formData.trEvaluationStep.evaluatedLevel) {
            state.formData.trEvaluationStep = {
              ...state.formData.trEvaluationStep,
              requestorName: action.payload.requestorName,
              snowCaseId: action.payload.snowCaseId,
              evaluatedLevel: action.payload.evaluatedLevel,
              futureJobCode: action.payload.futureJobCode,
            };
          }
          state.showLastPage = true;
          state.formLoader = false;
        }
      )
      .addCase(getTrAdditionalDetails.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.errorFetchingTRDetails') || 'Error while fetching TR details'} !`
        );
        console.log("Error while fetching TR details !");
      })
      // submit last page (TR)
      .addCase(createTrHr.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(createTrHr.fulfilled, (state, action) => {
        state.trFormSubmitted = true;
        state.formLoader = false;
        successNotice(
          `${getTranslated('toast.success') || 'Success'}!!`,
          getTranslated('toast.formSubmitted') || 'Form submitted successfully'
        );
      })
      .addCase(createTrHr.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWentWrong') || 'Something went wrong, please try after sometime'
        );
      })
      // submit FM form
      .addCase(submitFM.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitFM.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoader = false;
        state.caseId = action.payload.caseId;
        showCaseId(
          `${getTranslated('toast.success') || 'Success'}!!`,
          `${getTranslated('toast.ThankYouForSubmitting') || 'Thank you for submitting your request. The form is locked and will be processed by the total rewards team. A case has been created successfully in MyServices with a case ID'} : ${state.caseId}, ${getTranslated('toast.youCanTrack') || 'you can track status of the request using the case ID in future'}.`
        );
        state.pdfGenerated = false;
      })
      .addCase(submitFM.rejected, (state) => {
        state.formLoader = false;
        state.pdfGenerated = false;
        console.log("Error is happen in submit FM form");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.formSubmissioFailed') || 'Form submission failed, please try after sometime'} !`
        );
      })
      // get manager name
      .addCase(getManagerName.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(getManagerName.fulfilled, (state, action) => {
        if (action.payload.managerName === null) {
          if (!state.caseIdFetched)
            warningNotice(
              `${getTranslated('toast.warning') || 'Note'} !!`,
              getTranslated('toast.youCanStillProceed') || "Can't fetch manager name, but you can still proceed"
            );
        }
        state.formData.positionDetails.newManagerName =
          action.payload.managerName;
      })
      .addCase(getManagerName.rejected, (state, action) => {
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.cannotFetchManagerName') || 'Cannot fetch Manager name, please try after sometime'
        );
      })
      // generate pdf response
      .addCase(generatePdf.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(generatePdf.fulfilled, (state, action) => {
        state.formLoader = false;
        state.pdfGenerated = true;
      })
      .addCase(generatePdf.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.formSubmissioFailed') || 'Form submission failed, please try after sometime'} !`
        );
      })
      // get Form Status
      .addCase(getFormStatus.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(getFormStatus.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.formStatusFetched = true;
        state.caseIdFetched = action.payload.caseId;
        if (action.payload.status === "Submitted" || action.payload.status === "Completed") {
          state.formLocked = true;
        }
        if (action.payload.language) {
          state.language = action.payload.language
        }
      })
      .addCase(getFormStatus.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.cannotFetchFormStatus') || 'Cannot fetch form status, please try after sometime'} !`
        );
      })
      // get demo api response
      .addCase(getQueryParams.pending, (state) => {
        // state.formLoader = true;
      })
      .addCase(getQueryParams.fulfilled, (state, action) => {
        if (action.payload) {
          state.gpId = action.payload.incumbentGPID;
          state.formData.positionDetails.purposeOfRequestId =
            action.payload.purposeOfRequest;
          state.formData.positionDetails.proposedEvaluatedLevelId =
            action.payload.proposedLevel;
          state.formData.positionDetails.proposedCompensatedLevelId =
            action.payload.proposedLevel;
          state.formData.positionDetails.functionCode = action.payload.function;
          state.formData.positionDetails.subFunctionCode =
            action.payload.subFunction;
          state.requestOfForm = action.payload.requestOfForm;
          if (action.payload.language) {
            state.language = action.payload.language
          }
        }
        const currentUserGPID = action.meta.arg.userGPID;
        if (currentUserGPID === action.payload.userGPID || currentUserGPID === action.payload.createdBy || action.payload.sharedWith?.includes(currentUserGPID)) {
          state.authorize = true;
          state.authorizationFailed = false;
        } 
      })
      .addCase(getQueryParams.rejected, (state) => {
        state.authorize = false;
        state.authorizationFailed = true;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('alert.noAccess') || 'User do not have access to the form'} !`
        );
      })
      // using position Id
      .addCase(getPreDefinedDataPositionId.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getPreDefinedDataPositionId.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          //StepOne level set
          if (payload.stepOne !== null) {
            state.stepOneEditable = false;
            payload.stepOne.currentEvaluatedLevelId = findLevelObjByName(
              payload.stepOne.currentEvaluatedLevelId,
              state.masterData.positionDetails.currentEvaluatedLevels
            ).value;

            //Update stepOne state
            state.formData.positionDetails = {
              ...state.formData.positionDetails,
              ...payload.stepOne,
            };
          } else {
            state.stepOneEditable = true;
          }

          if (state.language == 'es' && payload.currentJobTitle && payload.currentJobTitle.nameEsMx)
            state.formData.positionDetails.jobTitleId = payload.currentJobTitle.nameEsMx
          //Loader
          state.formLoader = false;
        }
      )
      .addCase(getPreDefinedDataPositionId.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch prepopulated data api");
        //show error
        errorNotice(
          "Error !!",
          "Cannot fetch data using Position ID, please try after sometime"
        );
      })
      //Get user name from gpid
      .addCase(getUserNameFromGpid.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getUserNameFromGpid.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          //Update stepOne state
          if (payload.stepOne !== null) {
            state.formData.positionDetails = {
              ...state.formData.positionDetails,
              ...payload.stepOne,
            };
          }
          // state.formLoader = false;
        }
      )
      .addCase(getUserNameFromGpid.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch user data from gpid api");
        //show error
        errorNotice("Error !!", "Cannot fetch user data using  GPID");
      });
  },
});

export const {
  nextStep,
  prevStep,
  goToLastPage,
  goFromLandingPageToForm,
  goToLandingPage,
  viewForm,
  updateReqId,
  updateUserId,
  updateOktaUserData,
  updateOktaUserRole,
  updatePositionDetailFormData,
  updateRoleContextStaticFormData,
  updateRoleContextFormData,
  updatePurposeOfRequestId,
  updateIncumbentGPID,
  updateProposedEvaluatedLevelId,
  updateIncumbentName,
  updateFunctionCode,
  updateSubFunctionCode,
  updateStepOneMasterData,
  updateTrEvaluationStepFormData,
  updateFormLocked,
  updateAccessToLastPage,
  updateComments,
  updateFormStatusFetched,
  updateAuthorize,
  updateFmActiveStep,
  updateClickedOnDraft,
  updateIsReviewAndSubmit,
  skipLandingPage,
  updateStepNumber,
  updateLanguage
} = fmReEvaluateSlice.actions;

export default fmReEvaluateSlice.reducer;
