import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { FormControlFlex } from "../styled";
import { Grid, InputAdornment } from "@mui/material";
import { ToolTip } from "./ToolTip";
import { TOOLTIPS } from "../constants";
import { TRUE_FALSE_OPTIONS } from "../../app/constants";
import { getFieldNameFromKey } from "../functions/getFieldNameFromKey";
import { getTranslated } from "../functions/getTranslatedLevel";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

interface IFunctionalkpi {
  updateState: Function;
  values: any;
  roleImpactOptions: Array<any>;
  functionalKpiOptions: Array<any>;
  data: any;
  state: any;
}

const Functionalkpi = ({
  updateState,
  values,
  roleImpactOptions,
  functionalKpiOptions,
  data,
  state
}: IFunctionalkpi) => {
  /*
  //
  props .values
  [
        {
          functionalKPI: "",
          value: "test val",
          roleImpactId: null,
        },
        {
          functionalKPI: "",
          value: "test val",
          roleImpactId: null,
        },
  ]
  //
  updateState = { updateState}
  */

  const language = state?.language;

  React.useEffect(() => {
    let textKPIs: any[] = [];
    
    values.forEach((element: any) => {
      const kpi = functionalKpiOptions.find(ele => ele.value === element.functionalKPI)
      if(kpi && kpi.valueType === "Text")
      textKPIs.push(element)
    });
    
    if (functionalKpiOptions.length && state && state.oktaUserRole === "tr") {
      translate(textKPIs);
    }
  }, [functionalKpiOptions]);

  const translate = (textKPIs: any[]) => {
    let text = { q: textKPIs.map((text: any)=> text.value) };
    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += `&target=${language}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(text),
    })
      .then((res) => res.json())
      .then((response) => {
        const translatedValue = response.data.translations;
        let items = [...values];
        textKPIs.forEach((row: any, i) =>{
          const key = values.indexOf(row)
          let item = { ...items[key] };
          item.value = translatedValue[i].translatedText
          items[key] = item
        })
        const detectedSourceLanguage =
          response.data.translations[0].detectedSourceLanguage;
        if (detectedSourceLanguage !== language) {
          updateState({
            functionalKPIs: items,
          });
        }
      })
      .catch((error) => {
        console.log("error ", error);
      });
  };

  const isNumericField = (i: number) => {
    let result = false;
    const functionalKPIValue = values[i].functionalKPI;
    functionalKpiOptions.map((option) => {
      if (option.value == functionalKPIValue && option.valueType == "Currency")
        result = true;
    });
    return result;
  };

  const isDropdownField = (i: number) => {
    let result = false;
    const functionalKPIValue = values[i].functionalKPI;
    functionalKpiOptions.map((option) => {
      if (option.value == functionalKPIValue && option.valueType == "Dropdown")
        result = true;
    });
    return result;
  };

  const handleChange = (
    key: any,
    objProp: string,
    event: any,
    inputType: any = null
  ) => {
    if (inputType != null && inputType == "multiline") {
      let lines = event.target.value.split(/\r|\r\n|\n/);
      let count = lines.length;
      console.log(count);
      if (count >= 11) {
        return false;
      }
    }
    ////
    // 1. Make a shallow copy of the items
    let items = [...values];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[key] };
    // 3. Replace the property you're intested in
    let value = event.target.value;
    if (objProp == "value") {
      if (isNumericField(key)) {
        value = value.replaceAll(/[^0-9.]/g, "");
        let splitedValue = value.split(".");
        let firstValue = Number(splitedValue[0]).toLocaleString();
        let secondValue = "";
        if (splitedValue.length > 1) secondValue = "." + splitedValue[1];
        let finalValue = firstValue + secondValue;
        value = finalValue;
        // console.log(value);
      }
    }

    item[objProp] = value;
    // 4. Put it back into our array.
    items[key] = item;
    updateState({
      ["functionalKPIs"]: items,
    });

    if (values[key].functionalKPI == "F11") {
      let selectedValue = event.target.value;
      let newValues = [...values];
      newValues[key] = { ...newValues[key], value: selectedValue };

      let name = getFieldNameFromKey(
        functionalKpiOptions[key].funcKPIDropdownValueList,
        selectedValue
      );
      if (name == "Yes") {
        let newFields = functionalKpiOptions
          .slice(functionalKpiOptions.length - 3, functionalKpiOptions.length)
          .map((element) => {
            return {
              functionalKPI: element.value,
              value: null,
            };
          });
        updateState({
          functionalKPIs: [...newValues, ...newFields],
        });
      } else if (name == "No") {
        if (
          getFieldNameFromKey(
            functionalKpiOptions[key].funcKPIDropdownValueList,
            values[key].value
          ) == "Yes"
        ) {
          updateState({
            functionalKPIs: newValues.slice(0, newValues.length - 3),
          });
        }
      }
    }
  };

  return (
    <>
      {/* <div className="repeat-wrapper" style={{ marginTop: 20 }}>
        <FormControlFlex className="inputSet">
          <div className="label">
            <span style={{ fontWeight: "bold" }}>Functional KPIs</span>
            <ToolTip title={TOOLTIPS.functionalKPIs} />
          </div>
          <div className="select-wrapper">
            <Grid container spacing={1} key="heading">
              <Grid item xs={12} md={6}>
                <span style={{ fontWeight: "bold" }}>Value</span>
              </Grid>
              <Grid item xs={12} md={6}>
                <span style={{ fontWeight: "bold" }}>Role Impact</span>
              </Grid>
            </Grid>
          </div>
        </FormControlFlex>
      </div> */}
      <div className="input-set-wrapper">
        {values.map((item: any, i: number) => {
          return (
            <div className="repeat-wrapper" key={values.functionalKPI} style={{ marginTop: 20 }}>
              <FormControlFlex className="inputSet">
                <div className="label">
                  <span>
                    {getTranslated(`dynamic.${functionalKpiOptions[i]?.name}`) || functionalKpiOptions[i]?.name}
                    <span style={{ color: "white", marginLeft: 5 }}>*</span>
                  </span>
                  {/* {functionalKpiOptions[i]?.tooltip ? (
                    <ToolTip title={getTranslated(`dynamic.${functionalKpiOptions[i]?.tooltip}`) || functionalKpiOptions[i]?.tooltip} />
                  ) : null} */}
                </div>

                <div className="select-wrapper">
                  <Grid container spacing={1} key={i}>
                    <Grid item xs={12} md={12}>
                      <div>
                        {isNumericField(i) ? (
                          <FormControl fullWidth>
                            <TextField
                              error={
                                item.value !== null &&
                                item.value !== "" &&
                                item.value !== "0"
                                  ? false
                                  : true
                              }
                              disabled={
                                data.readOnly != undefined &&
                                data.readOnly === true
                                  ? true
                                  : false
                              }
                              inputProps={{
                                maxLength: 256,
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    MM USD
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              label=""
                              variant="outlined"
                              value={item.value}
                              onInput={(e) => handleChange(i, "value", e)}
                              title={item.value}
                            />
                          </FormControl>
                        ) : isDropdownField(i) ? (
                          <FormControl fullWidth>
                            <Select
                              error={
                                item.value !== null &&
                                item.value !== "" &&
                                item.value !== 0
                                  ? false
                                  : true
                              }
                              size="small"
                              id={"value" + i}
                              value={item.value}
                              label=""
                              onChange={(e) => handleChange(i, "value", e)}
                              disabled={
                                data.readOnly != undefined &&
                                data.readOnly === true
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem disabled value={0}>
                                <em>{getTranslated("others.select") || "Select"}</em>
                              </MenuItem>
                              {functionalKpiOptions[i].funcKPIDropdownValueList
                                ? functionalKpiOptions[
                                    i
                                  ].funcKPIDropdownValueList.map(
                                    (item: any) => {
                                      return (
                                        <MenuItem value={item.value} key={item.value}>
                                          {getTranslated(`dynamic.${item.name}`) || item.name}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                : TRUE_FALSE_OPTIONS.map(
                                    (item: any) => {
                                      return (
                                        <MenuItem value={item.value} key={item.name}>
                                          {item.name}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                            </Select>
                          </FormControl>
                        ) : (
                          <FormControl fullWidth>
                            <TextField
                              multiline
                              error={
                                item.value !== null &&
                                item.value !== "" &&
                                item.value !== 0
                                  ? false
                                  : true
                              }
                              disabled={
                                data.readOnly != undefined &&
                                data.readOnly === true
                                  ? true
                                  : false
                              }
                              inputProps={{ maxLength: 256 }}
                              size="small"
                              label=""
                              variant="outlined"
                              value={item.value == null ? "" : item.value}
                              onInput={(e) =>
                                handleChange(i, "value", e, "multiline")
                              }
                              title={item.value == null ? "" : item.value}
                            />
                          </FormControl>
                        )}
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <Select
                          error={
                            item.roleImpactId !== null &&
                            item.roleImpactId !== "" &&
                            item.roleImpactId !== 0
                              ? false
                              : true
                          }
                          size="small"
                          id={"roleImpactId" + i}
                          value={item.roleImpactId}
                          label=""
                          onChange={(e) => handleChange(i, "roleImpactId", e)}
                          disabled={
                            data.readOnly != undefined && data.readOnly === true
                              ? true
                              : false
                          }
                          title={
                            roleImpactOptions.find((o: any) => {
                              return o.value == item.roleImpactId;
                            })?.name
                          }
                        >
                          <MenuItem disabled value={""}>
                            <em>Select </em>
                          </MenuItem>
                          {roleImpactOptions.map((item: any, i: number) => {
                            return (
                              <MenuItem value={item.value} key={item.value}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid> */}
                  </Grid>
                </div>
              </FormControlFlex>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Functionalkpi;
