import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { FormControlFlex } from "../styled";
import Button from "@mui/material/Button";
import {
  getCurrentOrgChart,
  getFutureOrgChart,
} from "../../features/paq/paqSlice";
import { useAppDispatch } from "../hooks";
import { Link } from "@mui/material";
import { StringForFileUpload } from "../constants";
import { ToolTip } from "./ToolTip";
import { getTranslated } from "../functions/getTranslatedLevel";
import PaqSubmitDialog from "../../app/dialog/PaqSubmitDialog";

interface IFormAttachment {
  updateState: Function;
  data: any;
  uploadFile: Function;
}

const FormAttachment = ({ updateState, data, uploadFile }: IFormAttachment) => {
  let typeIndex = -1;
  const supportedTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.visio",
    "application/vnd.ms-visio.drawing",
  ];

  //handle Modal variables and methods - START
  const [isError, setIsError] = React.useState<boolean>(false);
  const [errMsg, setErrMsg] = React.useState<string>("");
  const handleModalOpen = (msg: string) => {
    setIsError(true)
    setErrMsg(msg);
  }
  const handleModalClose = () => {
    setErrMsg("");
    setIsError(false)
  }
  //handle Modal variables and methods - END

  const [fileName, setFileName] = React.useState("");
  const appDispatch = useAppDispatch();
  const paqState = data.state;

  const handleChange = (key: any, event: any) => {
    let fileSize = event.target.files[0].size;
    typeIndex = supportedTypes.indexOf(event.target.files[0].type);
    if (typeIndex !== -1) {
      //Max file size 20mb = 20971520 bytes (binary)
      if (fileSize <= 20971520) {
        const payload = {
          requestId: paqState.requestId,
          user: paqState.userId,
          file: event.target.files[0],
        };
        appDispatch(uploadFile(payload));
        setFileName(event.target.files[0].name);
      } else {
        handleModalOpen(
          getTranslated('alert.sizeLimit') || "File size has exceeded its maximum limit of 20MB. Please upload smaller file"
        );
      }
    } else {
      console.log("ErrorUploadingFile");
      handleModalOpen(`${getTranslated('alert.chooseAppropriateFile') || "Choose appropriate file"} !!!"`); 
    }
  };

  const downloadFile = () => {
    const payload = {
      requestId: paqState.requestId,
      fileName: data.value,
    };
    if (data.name === "currentStateOrgChartFileName")
      appDispatch(getCurrentOrgChart(payload));
    else if (data.name === "futureStateOrgChartFileName") {
      appDispatch(getFutureOrgChart(payload));
    }
  };

  React.useEffect(() => {
    if (data.value === StringForFileUpload)
      updateState({
        [data.name]: fileName,
      });
  }, [data.value]);

  return (
    <>
      <FormControlFlex>
        {isError && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} />}
        <div className="label">
          <span>
            {data.label}
            {data.required != undefined && data.required === true && (
              <span style={{ color: "white", marginLeft: 5 }}>*</span>
            )}
          </span>
          {/* {data.toolTipText != undefined ? (
            <ToolTip title={data.toolTipText} />
          ) : (
            ""
          )} */}
        </div>
        <div>
          <FormControl fullWidth>
            {!data.readOnly && (
              <Button variant="outlined" component="label">
                {getTranslated('others.uploadFile') || 'Upload File'}
                <input
                  type="file"
                  accept=".pdf,.doc,.png,.jpg,.jpeg,.docx,.ppt,.pptx,.xlsx,.xls,.vsdx,.vsd"
                  onChange={(e) => handleChange(data.name, e)}
                  onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                  hidden
                />
              </Button>
            )}
            {data.value && data.value != "" && (
              <Link
                sx={{ width: "fit-content", padding: "0.5em" }}
                href="#"
                underline="none"
                onClick={() => downloadFile()}
                style={{ fontFamily: "initial" }}
              >
                {data.value}
              </Link>
            )}
          </FormControl>
          <div className={`input-defination`} style={{ fontSize: 12 }}>
            {getTranslated("others.organizationChart") || "The attached file can be in .jpeg / .png / .pdf / .xlsx / .docx / .pptx / .vsdx format and should not exceed 20 MB in size."}
          </div>
        </div>
      </FormControlFlex>
    </>
  );
};
export default FormAttachment;
