import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEnglish from "./translation/en/translation.json";
import translationSpanish from "./translation/es/translation.json";
import dynamicTranslationSpanish from "./translation/es/dynamicTranslations.json";
import translationSpanishMX from "./translation/es-MX/translation.json";
import dynamicTranslationSpanishMX from "./translation/es-MX/dynamicTranslations.json";
import translationRussian from "./translation/ru/translation.json";
import dynamicTranslationRussian from "./translation/ru/dynamicTranslations.json";
import translationTurkish from "./translation/tr/translation.json";
import dynamicTranslationTurkish from "./translation/tr/dynamicTranslations.json";
import translationPolish from "./translation/pl/translation.json";
import dynamicTranslationPolish from "./translation/pl/dynamicTranslations.json";
import translationRomanian from "./translation/ro/translation.json";
import dynamicTranslationRomanian from "./translation/ro/dynamicTranslations.json";
import translationSerbian from "./translation/sr/translation.json";
import dynamicTranslationSerbian from "./translation/sr/dynamicTranslations.json";
import translationDutch from "./translation/nl/translation.json";
import dynamicTranslationDutch from "./translation/nl/dynamicTranslations.json";
import translationGerman from "./translation/de/translation.json";
import dynamicTranslationGerman from "./translation/de/dynamicTranslations.json";
import translationVietnamese from "./translation/vi/translation.json";
import dynamicTranslationVietnamese from "./translation/vi/dynamicTranslations.json";

import translationFrench from "./translation/fr/translation.json";
import dynamicTranslationFrench from "./translation/fr/dynamicTranslations.json";
import translationChinese from "./translation/zh/translation.json";
import dynamicTranslationChinese from "./translation/zh/dynamicTranslations.json";
import translationArabic from "./translation/ar/translation.json";
import dynamicTranslationArabic from "./translation/ar/dynamicTranslations.json";
import translationPortuguese from "./translation/pt-BR/translation.json";
import dynamicTranslationPortuguese from "./translation/pt-BR/dynamicTranslations.json";
import translationItalian from "./translation/it/translation.json";
import dynamicTranslationItalian from "./translation/it/dynamicTranslations.json";
import translationFrenchCA from "./translation/fr-CA/translation.json";
import dynamicTranslationFrenchCA from "./translation/fr-CA/dynamicTranslations.json";
import translationThai from "./translation/th/translation.json";
import dynamicTranslationThai from "./translation/th/dynamicTranslations.json";
import translationGreek from "./translation/el/translation.json";
import dynamicTranslationGreek from "./translation/el/dynamicTranslations.json";


const resources = {
  en: { translation: translationEnglish },
  es: { translation: { ...translationSpanish, dynamic: dynamicTranslationSpanish } },
  // es: { translation: translationSpanish },
  "es-MX": { translation: { ...translationSpanishMX, dynamic: dynamicTranslationSpanishMX } },
  ru: { translation: { ...translationRussian, dynamic: dynamicTranslationRussian } },
  tr: { translation: { ...translationTurkish, dynamic: dynamicTranslationTurkish } },
  pl: { translation: { ...translationPolish, dynamic: dynamicTranslationPolish } },
  ro: { translation: { ...translationRomanian, dynamic: dynamicTranslationRomanian } },
  sr: { translation: { ...translationSerbian, dynamic: dynamicTranslationSerbian } },
  nl: { translation: { ...translationDutch, dynamic: dynamicTranslationDutch } },
  de: { translation: { ...translationGerman, dynamic: dynamicTranslationGerman } },
  vi: { translation: { ...translationVietnamese, dynamic: dynamicTranslationVietnamese } },
  fr: { translation: { ...translationFrench, dynamic: dynamicTranslationFrench } },
  zh: { translation: { ...translationChinese, dynamic: dynamicTranslationChinese } },
  ar: { translation: { ...translationArabic, dynamic: dynamicTranslationArabic } },
  "pt-BR": { translation: { ...translationPortuguese, dynamic: dynamicTranslationPortuguese } },
  it: { translation: { ...translationItalian, dynamic: dynamicTranslationItalian } },
  "fr-CA": { translation: { ...translationFrenchCA, dynamic: dynamicTranslationFrenchCA } },
  th: { translation: { ...translationThai, dynamic: dynamicTranslationThai } },
  el: { translation: { ...translationGreek, dynamic: dynamicTranslationGreek } },


};

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
