import * as React from "react";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { getTranslated } from "../functions/getTranslatedLevel";
interface IFormRadio {
  updateState: Function;
  data: any;
}

const FormRadio = ({ updateState, data }: IFormRadio) => {
  const formData = useSelector((state: any) => {
    if (data.fmFormType === "fm") {
      return state.fm.formData.roleContext;
    } else if (data.fmFormType === "fmReEvaluate") {
      return state.fmReEvaluate.formData.roleContext;
    } else {
      return state.fmEvaluateNewRole.formData.roleContext;
    }
  });
  const disableOptionsArray = useSelector(
    (state: any) => state.fm.disableOptionsArray
  );
  const oneOfTheseOptionsArray = useSelector(
    (state: any) => state.fm.oneOfTheseOptionsArray
  );

  const disableOption = (answerId: any) => {
    let selectedOptionToSearchArray: any = [];
    let disable: boolean = false;
    // disable options if FORM LOCKED
    if (data.readOnly) {
      /* Old Code logic
      formData.forEach((key: any) => {
        if (key.answerId === answerId) {
          disable = false;
        } else {
          disable = true;
        }
      });
      */
      disable = true;
    } else {
      disableOptionsArray.forEach((key: any) => {
        if (key.disabledAnswerIds.includes(answerId)) {
          selectedOptionToSearchArray.push(key.selectedAnswerId);
        }
      });
      if (selectedOptionToSearchArray.length !== 0) {
        selectedOptionToSearchArray.forEach((element: any) => {
          formData.forEach((key: any) => {
            if (key.answerId === element) {
              disable = true;
            }
          });
        });
      }
      if (disable) {
        formData.forEach((key: any) => {
          if (key.answerId === answerId) {
            updateState({
              criteriaId: key.criteriaId,
              answerId: 0,
            });
          }
        });
      }
    }
    return disable;
  };

  const uncheckOption = (answerId: any) => {
    oneOfTheseOptionsArray.forEach((element: any) => {
      if (element.selectedAnswerId === answerId) {
        element.disabledAnswerIds.forEach((option: any) => {
          formData.forEach((roleContext: any) => {
            if (roleContext.answerId === option) {
              updateState({
                criteriaId: roleContext.criteriaId,
                answerId: 0,
              });
            }
          });
        });
      }
    });
  };

  const autoSelectOneOption = (answerId: any) => {
    let answer: any
    disableOptionsArray.forEach((element: any) => {
      if (element.selectedAnswerId !== answerId) {
        answer = element.disabledAnswerIds[0];
        updateState({
          criteriaId: data.criteriaCode + 1,
          answerId: answer,
        });
      }
    });
  };

  const handleChange = (event: any) => {
    // FM-2 auto-select one out of four options
    if (
      disableOptionsArray.some(
        (el: any) => el.selectedAnswerId === parseInt(event.target.value)
      )
    ) {
      autoSelectOneOption(parseInt(event.target.value));
    }
    // FM-2 select one of the two radio questions
    uncheckOption(parseInt(event.target.value));
    updateState({
      criteriaId: data.criteriaCode,
      answerId: parseInt(event.target.value),
    });
  };

  const parseLines = (value: any) => {
    let newStr: string = value.replace(/\n/g, "<br/>");
    return newStr;
  };

  return (
    <>
      <div style={{ alignItems: "center", display: "flex" }}>
        <span>
          <b>{getTranslated(`dynamic.${data.criteriaInfo}`) || data.criteriaInfo}</b>
        </span>
        {/* {data.criteriaTooltip !== undefined &&
        data.criteriaTooltip !== "" &&
        data.criteriaTooltip !== null ? (
          <Tooltip title={getTranslated(`dynamic.${data.criteriaTooltip}`) || data.criteriaTooltip} arrow>
            <HelpOutlineIcon />
          </Tooltip>
        ) : (
          ""
        )} */}
      </div>
      <div style={{ marginTop: "10px" }}>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={data.value}
            onChange={(e) => handleChange(e)}
          >
            {data.options.map((opt: any) => (
              <Grid
                key={opt.optionCode}
                style={{ alignItems: "center", display: "flex" }}
              >
                <FormControlLabel
                  value={opt.optionCode}
                  control={<Radio disabled={disableOption(opt.optionCode)} />}
                  label={getTranslated(`dynamic.${opt.options}`) || opt.options}
                  className="text-hover"
                />
                {/* {opt.optionExplanation !== undefined &&
                opt.optionExplanation !== "" &&
                opt.optionExplanation !== null ? (
                  <Tooltip
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: parseLines(getTranslated(`dynamic.${opt.optionExplanation}`) || opt.optionExplanation),
                        }}
                      ></span>
                    }
                    arrow
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                ) : (
                  ""
                )} */}
              </Grid>
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};
export default FormRadio;
